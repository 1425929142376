import clsx from 'clsx'

import { Typography, TextField, Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { useStyles } from './Footer.style'

import { FooterLogic } from './Footer'

function Footer() {
  const classes = useStyles()
  const { setEmail, email, handleSubmit } = FooterLogic()

  return (
    <>
      <div className={classes.root} style={{ backgroundColor: "#6943FF", marginTop: "5vw" }}>
        <Grid container spacing={1}>
          {/*Column-1 logo column */}
          <Grid item spacing={1} md={6} lg={3} xs={10}>
            <Link style={{ color: "#fff", textDecoration: "none" }} to="/">
              <Typography variant="h4">IEMSECURE</Typography>
            </Link>
            <Grid container className={classes.top}>
              {/*Grid for store icons*/}
            </Grid>
          </Grid>

          {/*Column-2 Company column */}
          <Grid item spacing={6} md={6} lg={3} xs={6}>
            <Link to="/about-us" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                About Us
              </Typography>
            </Link>
            <Link to="/Contact-us" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                Contact Us
              </Typography>
            </Link>
            <Link to="/Pricing" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Pricing
              </Typography>
            </Link>
            <Link to="/plagiarism-faq" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Plagiarism FAQ{' '}
              </Typography>
            </Link>
          </Grid>

          {/*Column-2 Company column */}
          <Grid item spacing={6} md={6} lg={3} xs={4}>
            <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Terms and Condition{' '}
              </Typography>
            </Link>
            <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Privacy Policy{' '}
              </Typography>
            </Link>
            <Link to="/release-notes" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Release Notes{' '}
              </Typography>
            </Link>
            <Link to="/bug-bounty" style={{ textDecoration: 'none' }}>
              <Typography className={clsx(classes.list, classes.top)}>
                {' '}
                Bug Bounty Programe{' '}
              </Typography>
            </Link>
          </Grid>

          {/*Column-3 Dev column */}
          <Grid item spacing={6} md={6} lg={3} xs={5}>
            <Typography className={clsx(classes.list, classes.top)}> NewsLetter </Typography>
            <form noValidate className={classes.form}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className={classes.textField}
                size="small"
                value={email}
                label={email === '' ? 'Your Email' : ''}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ shrink: false }}
              />
              <Button
                onClick={handleSubmit}
                className={classes.btn}
              >
                Submit
              </Button>
            </form>

          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.copyright}>© Copyright IEM America Corporation All Rights Reserved. </Typography>

      </div>
    </>
  )
}

export default Footer
