import React from 'react'
import Preloader from "../components/CommingSoon/Preloader/Preloader";
import Timer from "../components/CommingSoon/Countdown/Timer";
import '../components/CommingSoon/styles.css'

function CommingSoon() {
  return (
    <div className="App">
      <div className="container">
        <h1 style={{ fontSize: '5rem' }}>IEMSecure</h1>
        <h1 style={{ color: '#fff', fontSize: '4.4rem' }}>
          Website
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Preloader />
      </div>
    </div>
  )
}

export default CommingSoon
