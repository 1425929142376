import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'

export const ResultPdfLogic = (userId, scanId) => {
  const [reportPDF, setReportPDF] = useState(false)
  const API = process.env.REACT_APP_API

  const { data: report } = useSelector((state) => state.reportDetails)

  //For token
  useEffect(async () => {
    if (report) {
      /*        const { data } = await axios.post(
        `${API}/pdf-report`,
        { userId: userId, scanId: scanId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      console.log(data)*/
      setReportPDF(true)
    }
  }, [API, report])
  console.log(reportPDF) //----------

  return { reportPDF, report, API }
}
