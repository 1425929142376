import { Grid, Typography } from '@material-ui/core'
import React from 'react'

function PlansFAQ() {
  return (
    <div>
      <Typography align="center" variant="h2">
        Frequently asked questions
      </Typography>
      <Grid container>
        <Grid item md>
          <h2>What does a credit cover?</h2>
          <p>
            One credit covers a single document of up to approximately 5,000 words (30,000
            characters). Documents larger than this will require multiple credits – e.g. a
            single document of up to 15,000 words would require 3 credits; two documents
            up to 5,000 words each would require 2 credits.
          </p>
        </Grid>
        <Grid item md>
          <h2>Can I get a refund?</h2>
          <p>
            Of course! We're happy to refund you for unused credits if you decide you
            don't want to use them. Just contact our support team to request a refund.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default PlansFAQ
