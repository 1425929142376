export const tiers = [
  {
    credits: 'In Scope',
    description: [
      'Remote Code Execution (RCE)',
      'Web Shell Injection',
      ' Different types of Injections',
      '(SQLi,',
      ' XSS,',
      ' XXE, ',
      ' OS command,',
      ' LDAP etc.))',
      ' Security Misconfiguration',
      ' Sensitive Data Exposure',
      ' Components with Known Vulnerabilities',
      'Authentication bypass ',
      ' Insecure direct object references',
    ],
  },
  {
    credits: 'Out Of Scope',
    description: [
      'Reflected Cross Site Scripting (Self Xss)',
      ' Descriptive error messages (e.g. stack traces application or server errors)',
      'Misconfigured or lack of SPF records',
      ' Out of date software versions',
      'Content Spoofing',
      ' Vulnerabilities that are limited to unsupported browsers will not be accepted. Exploit must work at least on > IE 8',
      ' .htaccess downloadable file without a real security misconfiguration that can have security impact Login page or one of our websites over HTTP',
      ' Clickjacking or any issue exploitable through clickjacking Vulnerabilities in our 3rd party partners source code on which we don’t have any control regarding the fix. This vulnerability should be directly reported to the 3rd party host (e.g. Hubspot)',
      ' Lack of Secure and HTTP Only flags',
      'Username / Email enumeration',
      'Denial of Services (DOS)',
      'Parameter Tampering in Payment Gateway',
      'Cross Site Request Forgery (CSRF) in Contact form',
      'Social Engineering Attacks',
      'Cross Origin Resource Sharing (CORS) issues without a working Proof of Concept (POC)',
      'Weak SSL related issues',
    ],
    buttonText: 'Choose Professional',
    buttonVariant: 'contained',
  },
]
