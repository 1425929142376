import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  UploadedDocContainer: {
    padding: '1.8rem',
    backgroundColor: '#f0f0f0',
    width: '65vw',
    margin: '0 auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 330,
    display: 'flex',
  },
  UploadedDoc: {
    padding: '1rem 2.4rem',
    margin: '1rem 0.5rem',
  },

  root: {
    display: 'flex',
    minHeight: '100vh',
  },

  label: {
    padding: '3rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  uploadPaper: {
    width: '50vw',
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadFileText: {
    fontSize: '1.2rem',
  },
  subUploadText: {
    fontSize: '0.8rem',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#c2bffd4a',
    marginTop: '-0.6rem',
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  Paper: {
    padding: '2rem',
    height: '23rem',
    marginTop: '1rem',
    marginBottom: '4rem',
    width: '100%',
  },
  PaperMobile: {
    padding: '2rem',
    height: '24rem',
    marginTop: '4rem',
    marginBottom: '2.1rem',
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.2rem',
    fontWeight: 900,
    color: '#4c42fa',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  rightHeading: {
    marginTop: '4rem',
    fontSize: '2.6rem',
    fontWeight: 900,
    color: '#4c42fa',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  rightPara: {
    //  marginLeft: "3.9rem",
    marginTop: '1rem',
    fontSize: '1.16rem',
  },
  form: {
    marginTop: '1rem',
    width: '100%',
    border: '1px solid #9999f1b0',
    borderRadius: '6px',
  },
  submit: {
    backgroundColor: '#008a00',
    color: '#fff',
    marginTop: '0.6rem',
    borderRadius: '6px',
    padding: '0.4rem 2rem 0.4rem 2rem',
    fontSize: '1.04rem',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    width: '50%',
    '&:hover': {
      backgroundColor: '#008a00',
    },
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  reportDownloadBtn: {
    margin: '0.9rem auto 0 auto', 
    padding: '0.6rem 2rem', 
    backgroundColor: '#4536B7', 
    color: '#fff',
    textTransform: "none",
    fontSize: '1.02vw',
    "&:hover": {
      backgroundColor: '#34288E'
    }
  }
}))
