import { useState } from 'react';
import swal from 'sweetalert';
import { subscribeToNewsletter } from './helper';

export const FooterLogic = () => {
    const [email, setEmail] = useState('');
    const handleSubmit = async () => {
        const data = await subscribeToNewsletter(email);
        if (data && data.status == "ok") {
            swal("Success", data.message, "success")
        } else {
            swal("Failed", data.message, "error")
        }
    };

    return {
        setEmail, email, handleSubmit
    }
}