import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "0",
    marginTop: "-2.5vw"
  },
  paperCompleteProfile: {
    marginTop: '10rem',
    padding: "4rem"
  },
  paperTwoFA: {
    marginTop: '10rem',
    padding: "4rem"
  },
  BgPic: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: '-1',
    [theme.breakpoints.down('sm')]: {
      height: "100vh"
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: '#2A2D32',
    border: "2px solid #2A2D32",
    padding: "0.5vw 2.55vw",
    borderRadius: "40px",
    textTransform: "none",
    fontSize: "1.1vw",
    fontFamily: "'Montserrat', sans-serif",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    display: "block",
    "&:hover": {
      border: "2px solid #2A2D32",
      backgroundColor: "#fff",
      color: "#2A2D32",
      fontWeight: "semibold"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "4.9vw",
      padding: "1.5vw 5.55vw",
    }
  },
  heading: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 'bold',
    fontSize: "2.15vw",
    color: "#1E0970",
    textTransform: "none",
    marginTop: "0",
    [theme.breakpoints.down('sm')]: {
      fontSize: "7vw",
    }
  },
  fullCard: {
    backgroundColor: "#fff",
    padding: "0.2vw 2.4vw",
    borderRadius: "27px",
    width: "26vw",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down('sm')]: {
      width: "88vw",
      padding: "2.2vw 6vw",
    }
  },
  loginRedirect: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1.2vw",
    marginTop: "1vw",
    [theme.breakpoints.down('sm')]: {
      fontSize: "4.8vw",
      marginTop: "2vw",
    }
  },
  error: {
    backgroundColor: "none",
    padding: "0.2vw 2.4vw",
    width: "26vw",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: "1vw",
    [theme.breakpoints.down('sm')]: {
      width: "87vw",
    }
  },
  rememberMe: {
    fontSize: "0.9vw",
    [theme.breakpoints.down('sm')]: {
      display: "block",
      fontSize: "3.9vw",
      width: "100%",
    }
  },
  forgotPassword: {
    color: "#5145FF",
    position: "absolute",
    right: "0",
    fontSize: "0.9vw",
    top: "50%",
    transform: "translateY(-40%)",
    [theme.breakpoints.down('sm')]: {
      display: "block",
      fontSize: "3.9vw"
    }
  },
  authSocialIcons: {
    width: "20vw", 
    margin: "0.7vw",
    [theme.breakpoints.down('sm')]: {
      width: "75.7vw",
      margin: "4vw auto 2.5vw"
    }
  },
  socialIcon: {
    cursor: 'pointer',
    width: "2vw",
    marginTop: "-0.1vw",
    [theme.breakpoints.down('sm')]: {
      width: "7.7vw"
    }
  },
}))
