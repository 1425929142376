import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateProfile } from '../../../redux/actions/userProfile.js'

const CompleteProfile = (history) => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.userSignin)

  const [orgName, setOrgName] = useState(
    userInfo && userInfo.Org_name ? userInfo.Org_name : ''
  )
  const [contactNumber, setContactNumber] = useState(
    userInfo && userInfo.phn_no ? userInfo.phn_no : ''
  )

  const submitHandler = async (e) => {
    dispatch(UpdateProfile(userInfo.name, userInfo.email, contactNumber, orgName))
    history.push('/dashboard')
  }
  return { submitHandler, orgName, setOrgName, contactNumber, setContactNumber }
}

export default CompleteProfile
