import React from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Alert from '@material-ui/lab/Alert'

import { useStyles } from '../auth.style'

import BackgroundPic from "../../../assets-new/Login Bg.png";

import LoginLogic from './login'
import SocialLogin from '../../../components/SocialAuth'

export default function LoginScreen({ history }) {
  const classes = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))

  const {
    setEmail,
    email,
    password,
    setPassword,
    rememberMe,
    setRememberMe,
    LoginSubmitHandler,
    showPassword,
    setShowPassword,
    error,
  } = LoginLogic(history)

  return (
    <>
          <div className={classes.paper} style={{marginTop: "0"}}>
            <img src={BackgroundPic} className={classes.BgPic}/>
            <Typography component="h1" variant="h3" align="center" className={classes.heading}>
              Login
            </Typography>
            <br />
            {error && <Alert severity="error" className={classes.error}>{error}</Alert>}

          <div className={classes.fullCard}>
            <form className={classes.form} noValidate>
              <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="dense"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Grid container>
                  <Grid item xs={6}> 
                    <FormControlLabel
                        style={{marginTop: "0.5vw"}}
                        control={
                        <Checkbox
                          value={rememberMe}
                          onChange={(e) => setRememberMe(!rememberMe)}
                          color="primary"
                        />
                      }
                      label={<Typography variant="body2" className={classes.rememberMe}>Remember Me</Typography>}
                    />
                  </Grid>
                  <Grid item xs={6} style={{position: 'relative', verticalAlign: 'middle'}}>
                  <Link to="/password-reset" variant="body2" className={classes.forgotPassword}>
                    Forgot password?
                  </Link>
                  </Grid>
              </Grid>
              <Button
                type="submit"
                className={classes.submit}
                onClick={LoginSubmitHandler}
                style={{marginTop: "0.7vw"}}
              >
                Login
              </Button>
            </form>

            <SocialLogin history={history} />
            </div>

            <Container maxWidth="xs" justify="center">
              <Grid container>
              <Grid item xs>
                <Typography align="center" variant="h6" className={classes.loginRedirect}>
                  Don't have an account? &nbsp;
                  <Link to="/register">
                    Register
                  </Link>
                </Typography>
              </Grid>
            </Grid>
              </Container>
          </div>
    </>
  )
}
