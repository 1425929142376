import { useContext, useState, useEffect } from 'react'
import { FirebaseContext } from '../../../firebase/firebase'
import { useSelector } from "react-redux";
import swal from 'sweetalert';

const LoginLogic = (history) => {
  const { api } = useContext(FirebaseContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    if (userInfo && userInfo.twoFactorEnabled || userInfo && userInfo.twoFactorSmsEnabled || userInfo && userInfo.twoFactorAuthenticatorEnabled) {
      history.push("/twoFaOptions");
    } else if (userInfo) {
      history.push("/dashboard")
    }
  }, [history, userInfo])


  const LoginSubmitHandler = async (e) => {
    e.preventDefault()

    const errMsg = await api.signinUser(email, password, rememberMe)
    console.log(errMsg)
    if (errMsg) {
      setError(errMsg)
    } else {
      swal("Successful", "You will be logged in few seconds", "success")
    }
  }

  return {
    rememberMe,
    setRememberMe,
    LoginSubmitHandler,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    error,
  }
}

export default LoginLogic
