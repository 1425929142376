import React from 'react'

import HeroSection from '../../components/Home/HeroSection/HeroSection'
import ScanForm from '../../components/Home/ScanForm/ScanForm'
import ContactUsRedirect from '../../components/Home/ContactUsRedirect/ContactUsRedirect'
import Pricing from '../../components/PricingCards'
import Steps from '../../components/Home/Steps'
import Footer from '../../components/Footer'

import { Typography } from '@material-ui/core'
import { useStyles } from './home.style'

const Home = () => {
  const classes = useStyles()
  return (
    <div style={{overflowX: 'hidden'}}>
      {/* Loading Animation */}
      <div className={classes.first}></div>
      <div class={classes.second}></div>
      <div class={classes.third}></div>
      <div class={classes.fourth}></div>
      
      {/* <div style={{overflowX: 'hidden'}}> */}
        <HeroSection />
        <ScanForm />
        <Steps />
        {/**Pricing heading */}
        {/* <Typography
          component="h1"
          variant="h2"
          align="center"
          className={classes.choosePlanHeading}
        >
         Choose your plan
        </Typography>
        <Pricing /> */}
        <ContactUsRedirect />
      {/* </div> */}

      <Footer />
    </div>
  )
}

export default Home
