import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  headingGradient: {
    backgroundImage: 'linear-gradient(90deg, #5145FF, #9A3CFD)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  scanForm: {
    marginTop: '10vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15vw',
    },
  },
  heading: {
    fontFamily: 'Favorit',
    fontSize: '4.4vw',
    fontWeight: '500',
    textAlign: 'right',
    position: 'relative',
    left: '22.9vw',
    lineHeight: '3.8vw',
    width: '35.5vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10.4vw',
      width: '85.5vw',
      lineHeight: '4.8vw',
      left: '5.9vw',
    },
  },
  description: {
    fontFamily: 'ITC Avant Garde Gothic Std Md',
    fontSize: '1.1vw',
    textAlign: 'right',
    position: 'relative',
    marginTop: '2.2vw',
    left: '22.9vw',
    width: '35.5vw',
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4.4vw',
      width: '85.5vw',
      marginTop: '6.2vw',
      left: '5.9vw',
    },
  },
  freeImg: {
    position: 'relative',
    top: '0.55vw',
    display: 'inline',
    width: '9.5vw',
    marginLeft: '0.7vw',
    [theme.breakpoints.down('sm')]: {
      width: '22.5vw',
      top: '2vw',
    },
  },
  rightHeading: {
    marginTop: '4rem',
    fontSize: '2.6rem',
    fontWeight: 900,
    color: '#4c42fa',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  form: {
    position: 'relative',
    left: '6.5vw',
    marginTop: '1.7rem',
    width: '80%',
    borderRadius: '19px',
    backgroundColor: '#D7D4FF',
    padding: '1rem 2rem',
    [theme.breakpoints.down('sm')]: {
      width: '87%',
    },
  },
  submit: {
    backgroundColor: '#FF4B4B',
    display: 'block',
    color: '#fff',
    marginTop: '2.2rem',
    borderRadius: '9px',
    padding: '0.6rem 1rem',
    fontSize: '1.26rem',
    fontFamily: 'ITC Avant Garde Gothic Std',
    width: '10.2vw',
    position: 'relative',
    left: '49.1vw',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FF4B4B',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '4.4vw',
      width: '38.5vw',
      marginTop: '5.2vw',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  leftImage: {
    width: '90%',
    marginLeft: '5vw',
    [theme.breakpoints.down('sm')]: {
      // width: "83.5vw",
      // marginLeft: "7vw",
      // marginTop: "12vw",
      display: 'none',
    },
  },
  imageGrid: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  formGrid: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      width: '100%',
    },
  },
}))
