import {
  FETCH_RESULT_DETAILS_FAILED,
  FETCH_RESULT_DETAILS_REQUEST,
  FETCH_RESULT_DETAILS_SUCCESS,
  FETCH_RESULT_LIST_FAILED,
  FETCH_RESULT_LIST_REQUEST,
  FETCH_RESULT_LIST_SUCCESS,
} from '../constants'

import axios from 'axios'

export const reportList = (userId, API) => async (dispatch) => {
  dispatch({
    type: FETCH_RESULT_LIST_REQUEST,
  })

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const { data } = await axios.post(`${API}/report/list`, { userId }, config)
    dispatch({
      type: FETCH_RESULT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_RESULT_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const reportDetails = (userId, scanId, API) => async (dispatch) => {
  dispatch({
    type: FETCH_RESULT_DETAILS_REQUEST,
  })

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const { data } = await axios.post(`${API}/report/${scanId}`, { userId }, config)
    console.log(data)

    dispatch({
      type: FETCH_RESULT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_RESULT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
