import axios from 'axios'
import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_FETCH,
  USER_PROFILE_FAILED,
  USER_PROFILE_DELETE,
  USER_PROFILE_DELETE_FAILED,
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_FAILED,
} from '../constants'

const API = process.env.REACT_APP_API
const config = {
  'Content-Type': 'application/json',
}
/**
 * @returns GET user data to show in profile
 */
export const userProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    })
    const { data } = await axios.get(`${API}/users-profile/${id}`)
    dispatch({
      type: USER_PROFILE_FETCH,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * @description: Updates the data of user profile
 */
export const UpdateProfile = (name, email, contact, orgName, orgEmail, orgAddress, orgPhnNo) => async (dispatch) => {
  const body = {
    name,
    email,
    contact,
    orgName,
    orgEmail,
    orgAddress,
    orgPhnNo
  }
  try {
    console.log(body)
    const { data } = await axios.put(`${API}/users-profile/update`, body, config)
    console.log(data)
    dispatch({
      type: USER_PROFILE_UPDATE,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_PROFILE_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * @returns deletes user profile
 */
export const deleteUserProfile = (id) => async (dispatch) => {
  try {
    console.log(API)
    const { data } = await axios.post(`${API}/users-profile/delete/${id}`, config)
    console.log(data)

    dispatch({
      type: USER_PROFILE_DELETE,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_PROFILE_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
