import { Button, TextField, Paper, Container, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../auth.style'
import Footer from '../../../components/Footer'

import twoFactorAuth from './twoFactorAuth'

export default function TwoFactorAuth({ history }) {
    const classes = useStyles()
    const { submitHandler, authCode, setAuthCode } = twoFactorAuth(history)

    return (
        <>
            <Container>
                <Grid container component="main" className={classes.root}>
                    <Grid item xs={false} sm={false} md={3}>
                        {' '}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} component={Paper} className={classes.paperTwoFA}>
                        <Typography component="h6" variant="h4">
                            Two Factor Authentication
                        </Typography>
                        <br />
                        <Typography component="h1" variant="h5"></Typography>
                        <form className={classes.form} noValidate>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="authCode"
                                label="Auth Code"
                                name="authCode"
                                autoComplete="authCode"
                                autoFocus
                                value={authCode}
                                onChange={(e) => setAuthCode(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={submitHandler}
                            >
                                Verify
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={false} sm={false} md={3}></Grid>
                </Grid>
            </Container>

            <Footer />
        </>
    )
}
