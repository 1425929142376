import { Button, Paper, Typography, CircularProgress } from '@material-ui/core'
import { ResultPdfLogic } from './ResultPdf'

import { useStyles } from './main.style'
function ResultPdf({ userId, scanId }) {
  const { reportPDF, report, API } = ResultPdfLogic(userId, scanId)
  const classes = useStyles()
  return (
    <div>
      {!reportPDF ? (
        <CircularProgress style={{ margin: '2rem' }} />
      ) : (
        <>
          {/*   <iframe src={reportPDF} /> */}
          <a href={`${API}/pdf-report/${scanId}`} style={{ textDecoration: 'none' }}>
            <Button
              className={classes.reportDownloadBtn}
              size="small"
              variant="contained"
            >
              Get Report
            </Button>
          </a>
        </>
      )}
    </div>
  )
}

export default ResultPdf
