import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const TwoFAOptions = () => {

  const [twoFaEmail, setTwoFaEmail] = useState("")
  const [twoFaSms, setTwoFaSms] = useState("")
  const [twoFaAuthenticator, setTwoFaAuthenticator] = useState("")

  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    if (userInfo && userInfo.twoFactorEnabled) {
      setTwoFaEmail("two-fa-email")
    }
    if (userInfo && userInfo.twoFactorSmsEnabled) {
      setTwoFaSms("two-fa-sms")
    }
    if (userInfo && userInfo.twoFactorAuthenticatorEnabled) {
      console.log("ff");
      setTwoFaAuthenticator("two-fa-authenticator")
    }
  }, [userInfo, twoFaEmail, twoFaSms, twoFaAuthenticator])


  return { twoFaAuthenticator, twoFaEmail, twoFaSms }
}


export const tiers = [
  {
    title: 'SMS',
    description: ["Get your Verification code from SMS"],
    buttonText: 'Write Your Code',
    buttonVariant: 'contained',
    route: '/twoFactorAuth',
    twoFA: "two-fa-sms"
  },
  {
    title: 'Google Authenticator',
    description: [
      "Get your verification token from google authenticator",
    ],
    buttonText: 'Write Your Code',
    buttonVariant: 'contained',
    route: '/twoFactorAuthenticator',
    twoFA: "two-fa-authenticator"
  },
  {
    title: 'Email',
    description: [
      "Get your verification code from email",
    ],
    buttonText: 'Write Your Code',
    buttonVariant: 'contained',
    route: '/twoFactorAuth',
    twoFA: "two-fa-email"
  },
];