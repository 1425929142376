import { useEffect } from 'react'
import { BrowserRouter, Switch, Redirect, Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ScrollToTop from './ScrollTotop'

import { NavRoute } from './Layout/NavRoute'
import { DashboardNavRoute } from './Layout/DashboardNavRoute'

import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsandConditions from './pages/TandC'
import ReleaseNotes from './pages/ReleaseNotes'
import Plag from './pages/Plag'
import ContactUs from './pages/Contact'
import AboutUs from './pages/About'
import Pricing from './pages/Pricing'
import Login from './pages/Auth/Login'
import RegisterPage from './pages/Auth/Register'
import ResetPassword from './pages/Auth/ResetPassword'
import CompleteProfile from './pages/Auth/ProfileComplete'
import TwoFactorAuth from './pages/Auth/TwoFactorAuth'
import TwoFactorAuthenticator from './pages/Auth/TwoFactorAuthenticator'
import TwoFaOtions from './pages/Auth/TwoFaOtions'


import NewScanDashboard from './pages/Dashboard/NewScan'
import Profile from './pages/Dashboard/Profile'
import PricingPlans from './pages/Dashboard/Pricing'
import ScanList from './pages/Dashboard/ScanList'
import ScanResults from './pages/Dashboard/ScanResults'
import CompareText from './pages/Dashboard/CompareText'

import Cookie from './components/Cookie'
import CommingSoon from './pages/CommingSoon'
//Chatbot
import tawkTo from 'tawkto-react'
import BugBounty from './pages/BugBounty'

function Routes() {
  const { userInfo } = useSelector((state) => state.userSignin)

  //Tawk to chatbot
  const tawkToPropertyId = '61530e2825797d7a890138f6'
  const tawkToKey = '1fgm6uk36'
  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Route exact path="/" component={CommingSoon} />
          <Switch>
            {/* {userInfo && <Redirect exact from="/login" to="/dashboard" />} */}
            {userInfo && <Redirect exact from="/register" to="/dashboard" />}

            <NavRoute exact path="/home" component={Home} />

            <NavRoute exact path="/Register" component={RegisterPage} />
            <NavRoute exact path="/Login" component={Login} />
            <NavRoute exact path="/password-reset" component={ResetPassword} />
            <Route exact path="/complete-profile" component={CompleteProfile} />
            <Route exact path="/twoFactorAuth" component={TwoFactorAuth} />
            <Route exact path="/twoFactorAuthenticator" component={TwoFactorAuthenticator} />

            <NavRoute exact path="/terms-and-conditions" component={TermsandConditions} />
            <NavRoute exact path="/release-notes" component={ReleaseNotes} />
            <NavRoute exact path="/plagiarism-faq" component={Plag} />
            <NavRoute exact path="/Contact-us" component={ContactUs} />
            <NavRoute exact path="/Pricing" component={Pricing} />
            <NavRoute exact path="/about-us" component={AboutUs} />
            <NavRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            <NavRoute exact path="/bug-bounty" component={BugBounty} />
            <NavRoute exact path="/blog" component={BugBounty} />
            <Route exact path="/twoFaOptions" component={TwoFaOtions} />


            <DashboardNavRoute exact path="/dashboard" component={NewScanDashboard} />
            <DashboardNavRoute exact path="/dashboard/scans" component={ScanList} />
            <DashboardNavRoute path="/dashboard/scans/:scanId" component={ScanResults} />
            <DashboardNavRoute exact path="/dashboard/pricing" component={PricingPlans} />
            <DashboardNavRoute exact path="/dashboard/profile" component={Profile} />
            <DashboardNavRoute exact path="/compare-text" component={CompareText} />
          </Switch>
        </ScrollToTop>
        <Cookie />
      </BrowserRouter>
    </>
  )
}

export default Routes
