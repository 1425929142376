import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  course: {
    flexGrow: 1,
    fontWeight: '690',
    color: '#6c63ff',
  },
  modalbtn: {
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[9],
    padding: theme.spacing(2, 4, 3),
    width: '75vw',
  },
  check: {
    maxHeight: "6rem",
    overflowX: "hidden"
    
  },
  margin: {
    margin: '0.5rem 0',
  },
  btn: {
    margin: '0 2.1rem 0 0.1rem',
  },
  wordcount: {
    marginTop: theme.spacing(-5),
  },
  modalBtn_Heading: {
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    fontSize: "1.17vw",
  },
  modalBtn_InputText: {
    fontFamily: "'ITC Avant Garde Gothic Std', 'Montserrat', sans-serif",
    fontSize: "0.95vw",
    fontWeight: '500',
    marginTop: "0.5vw"
  },
  suggestedWord: {
    fontSize: "1rem"
  }
}))
