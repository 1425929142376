import React, { useContext } from 'react'
import { FirebaseContext } from '../../firebase/firebase'

import { Grid, Divider, Typography } from '@material-ui/core'

import {useStyles} from "../../pages/Auth/auth.style";

import GoogleIcon from "../../assets-new/Google icon.svg";
import FacebookIcon from "../../assets-new/Facebook icon.svg";

function SocialLogin({ history }) {
  const classes = useStyles();

  const { api } = useContext(FirebaseContext)

  const googleAuth = async () => {
    const errMsg = await api.googleSignUp()
    if (errMsg) {
      alert(errMsg)
    } else {
      setTimeout(() => {
        history.push('/complete-profile')
      }, 1000)
    }
  }

  const facebookAuth = async () => {
    const errMsg = await api.facebookSignUp()
    if (errMsg) {
      alert(errMsg)
    } else {
      setTimeout(() => {
        history.push('/complete-profile')
      }, 1000)
    }
  }

  const twitterAuth = async () => {
    const errMsg = await api.twitterSignUp()
    if (errMsg) {
      alert(errMsg)
    } else {
      setTimeout(() => {
        history.push('/complete-profile')
      }, 1000)
    }
  }

  return (
    <div style={{ margin: '0' }}>
      <Divider variant="middle" flexItem />
      <Typography variant="subtitle2" color="primary" align="center">
        Or register with
      </Typography>

      <Grid container justify="center" className={classes.authSocialIcons}>
        <Grid item xs={2} align="center">
          <img src={GoogleIcon}
            onClick={googleAuth}
            className={classes.socialIcon}
          />
        </Grid>
        <Grid item xs={2} align="center">
          <img src={FacebookIcon} onClick={facebookAuth} 
          className={classes.socialIcon}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          
        <TwitterLoginButton
            onClick={twitterAuth}
            iconSize='30px'
            align='center'
        />
         
        </Grid> */}
      </Grid>
    </div>
  )
}

export default SocialLogin;
