import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: theme.spacing(4, 6),
  },
  top: {
    marginTop: theme.spacing(3),
  },
  list: {
    color: "#fff",
    margin: theme.spacing(3, 0),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#fff',
    borderRadius: '4px 0 0 4px',
    width: '15rem',
    marginTop: theme.spacing(2),
    color: '#b7b7b7',
    borderColor: '#b7b7b7 !important',
  },
  btn: {
    marginTop: '1rem',
    padding: '0.5rem 1rem',
    marginLeft: '-0.5rem',
    borderRadius: '0 4px 4px 0',
    backgroundColor: '#2A2D32',
    color: "#fff"
  },
  copyright: {
    marginTop: "4rem",
    color: "#fff",
    textAlign: "center"
  }
}))
