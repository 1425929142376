import {
    FETCH_RESULT_URL_REQUEST,
    FETCH_RESULT_URL_SUCCESS,
    FETCH_RESULT_URL_FAILED
} from '../constants'

const resultUrlReducer = (
    state = {
        error: null,
        data: "",
        loading: false,
    },
    action
) => {
    switch (action.type) {
        case FETCH_RESULT_URL_REQUEST:
            return {
                loading: true,
            }
        case FETCH_RESULT_URL_SUCCESS:
            return {
                loading: false,
                data: action.payload,
            }
        case FETCH_RESULT_URL_FAILED:
            return {
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export { resultUrlReducer }
