import {
  FILE_ADD_SUCCESS,
  FILE_ADD_FAILED,
  TEXT_ADD_FAILED,
  TEXT_ADD_SUCCESS,
} from '../constants'

const initialState = {
  error: null,
  data: [],
}

export const fileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_ADD_SUCCESS:
      return { data: action.payload }

    case FILE_ADD_FAILED:
      return { err: action.payload }

    default:
      return state
  }
}

export const textUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEXT_ADD_SUCCESS:
      return { data: action.payload }

    case TEXT_ADD_FAILED:
      return { err: action.payload }

    default:
      return state
  }
}
