import axios from 'axios'
import { useState } from 'react'

export const Contactlogic = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [number, setNumber] = useState()
  const [body, setBody] = useState()

  const [log, setLog] = useState(false)

  const handelSubmit = async () => {
    const API = process.env.REACT_APP_API
    const url = `${API}/contact` // text  || file

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const Body = {
      name,
      email,
      number,
      body,
    }
    await axios.post(url, Body, config)
    setLog(true)
    setName('')
    setEmail('')
    setBody('')
    setNumber('')
  }

  return {
    log,
    name,
    setName,
    email,
    setEmail,
    body,
    setBody,
    number,
    setNumber,
    handelSubmit,
  }
}
