import {
  FETCH_RESULT_LIST_FAILED,
  FETCH_RESULT_LIST_REQUEST,
  FETCH_RESULT_LIST_SUCCESS,
  FETCH_RESULT_DETAILS_REQUEST,
  FETCH_RESULT_DETAILS_SUCCESS,
  FETCH_RESULT_DETAILS_FAILED,
} from '../constants/reportConstant'

const reportListReducer = (
  state = {
    error: null,
    data: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_RESULT_LIST_REQUEST:
      return {
        loading: true,
      }
    case FETCH_RESULT_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case FETCH_RESULT_LIST_FAILED:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const reportDetailsReducer = (
  state = {
    error: null,
    data: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_RESULT_DETAILS_REQUEST:
      return {
        loading: true,
      }
    case FETCH_RESULT_DETAILS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case FETCH_RESULT_DETAILS_FAILED:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export { reportListReducer, reportDetailsReducer }
