import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { bugBountySubmit } from './helper'
import swal from 'sweetalert'

export const BugBountyLogic = () => {
  const history = useHistory()

  const [name, setName] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [email, setEmail] = useState('')
  const [issueName, setIssueName] = useState('')
  const [vulnerability, setVulnerability] = useState('')
  const [impact, setImpact] = useState('')
  const [steps, setSteps] = useState('')

  const submitHandeller = async (e) => {
    await bugBountySubmit(name, phoneNo, email, issueName, vulnerability, impact, steps)
    swal(
      'Successfully',
      'Your issue has been reported our team will contact you soon',
      'success'
    ).then((value) => {
      if (value) {
        history.go(0)
      }
    })
  }

  return {
    name,
    setName,
    phoneNo,
    setPhoneNo,
    email,
    setEmail,
    issueName,
    setIssueName,
    vulnerability,
    setVulnerability,
    impact,
    setImpact,
    steps,
    setSteps,
    submitHandeller,
  }
}
