export const tiers = [
  {
    title: 'Personal Plan',
    credits: "1 Credit",
    price: '1.5',
    description: [
      ' 25 Pages',
      ' 6K Words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Choose Starter',
    buttonVariant: 'contained',
  },
  {
    title: 'Business',
    credits: "2 Credits",
    price: '9.41',
    description: [
      ' 1200 Pages per Year',
      ' 300K words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Choose Professional',
    buttonVariant: 'contained',
  },
  {
    title: 'Premium',
    credits: "3 Credits",
    price: '12',
    description: [
      ' 1400 Pages per Year',
      ' 400K words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Choose Organization',
    buttonVariant: 'contained',
  },
]
