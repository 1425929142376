import React from 'react'
import {
  Button,
  Container,
  TextField,
  Fade,
  Typography,
  Modal,
  Backdrop,
} from '@material-ui/core'

import { useStyles } from './modal.style'

import { TextModalLogic } from './textModal'

function TextModal({ history, setLoadingScan, setIsDisabled, isDisabled }) {
  const classes = useStyles()

  const { open, handleOpen, handleClose, handleText, handleSubmit, counter, showSuggestions } =
    TextModalLogic(history, setLoadingScan, setIsDisabled, isDisabled)

  return (
    <>
      <Container aria-disabled={isDisabled}>
        <div onClick={handleOpen} className={classes.modalbtn}>
          <Typography color="primary" variant="h6" align="center" className={classes.modalBtn_Heading}>
            Want to paste some text?
          </Typography>
          <Typography align="center" variant="body2" className={classes.modalBtn_InputText}>
            Type your text here...
          </Typography>
        </div>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h4" color="primary" id="transition-modal-title">
              Paste Text
            </Typography>
            <p id="transition-modal-description">
              <TextField
                required
                placeholder="Your Content"
                multiline
                fullWidth
                variant="outlined"
                rows={18}
                rowsMax={18}
                className={classes.margin}
                onChange={handleText}
                onPaste={handleText}
              />
              <br />
              <div className={classes.check}> {showSuggestions && showSuggestions.map((suggestion) => (
                <Typography>
                  {suggestion && suggestion.originalWord && (
                    <>
                      <span style={{ color: 'red' }}>
                        {suggestion.originalWord} :
                      </span> {' '}
                    </>
                  )}
                  {suggestion && suggestion.suggestedWords && suggestion.suggestedWords.map((suggestedWord) => (
                    <span style={{ color: 'green' }}>{suggestedWord}{' '}</span>
                  ))}
                </Typography>
              ))}
              </div>
              {/* {suggestedWords && suggestedWords.map((word) => (
                <>
                  {word === "" && <br />}
                  <span className={classes.suggestedWord}>
                    {word}
                  </span>
                  {' '}
                </>
              ))} */}
              <hr style={{color: "#ccc"}} />
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button variant="contained" color="default" onClick={handleClose}>
                Cancel
              </Button>

              <Typography align="right" color="primary" className={classes.wordcount}>
                {counter} Word
              </Typography>
            </p>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default TextModal
