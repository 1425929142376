import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FirebaseContext } from '../../../firebase/firebase'

import {
  userProfile,
  UpdateProfile,
  deleteUserProfile,
} from '../../../redux/actions/userProfile'
import { useDispatch, useSelector } from 'react-redux'
import { enableTwoFactorAuth, enableTwoFactorSmsAuth, purchaseHistoryHelper, uploadPhoto, toggleNewsletterSubscription, cancelPlanSubscription, addNewsletterSubscription, removeNewsletterSubscription, enableTwoFactorAuthenticator } from './helper'
import swal from 'sweetalert'

export const ProfileLogic = () => {
  const history = useHistory()
  const { userInfo } = useSelector((state) => state.userSignin)

  const { api } = useContext(FirebaseContext)

  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [orgName, setOrgName] = useState('')
  const [orgEmail, setOrgEmail] = useState('')
  const [orgAddress, setOrgAddress] = useState('')
  const [orgPhnNo, setOrgPhnNo] = useState('')
  const [photo, setPhoto] = useState('')
  const [purchaseHistory, setPurchaseHistory] = useState([])
  const [checked, setChecked] = useState(userInfo.twoFactorEnabled)
  const [smsChecked, setSmsChecked] = useState(userInfo.twoFactorSmsEnabled)
  const [authenticatorChecked, setAuthenticatorChecked] = useState(userInfo.twoFactorAuthenticatorEnabled);
  const [qrCodeEnabled, setQrCodeEnabled] = useState({
    enabled: false,
    qrCode: ""
  })

  const [checkedNewsletter, setCheckNewsletter] = useState(userInfo.newsletterSubscription)
  const [plan, setPlan] = useState('')
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(userProfile(userInfo._id))
    }
  }, [history, userInfo, dispatch])

  const { data, error, loading } = useSelector((state) => state.userProfile)

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(UpdateProfile(name, email, contact, orgName, orgEmail, orgAddress, orgPhnNo))
    swal("Success!", "Updated Profile", "success")
    setTimeout(() => {
      history.go(0)
    }, [1500])
  }

  const deleteHandler = async (e) => {
    e.preventDefault()
    dispatch(deleteUserProfile(userInfo._id))
  }

  const twoFactorHandler = async () => {
    const result = await enableTwoFactorAuth(userInfo.email);
    if (result.message === "Two Factor Enabled") {
      if (result.user.twoFactorEnabled) {
        swal("Successful", "Two Factor Authentication enabled successfully", "success")
      } else {
        swal("Successful", "Two Factor Authentication disabled successfully", "success")
      }
      setChecked(result.user.twoFactorEnabled)
    } else {
      swal("Failed", "Something went wrong", "error")
    }
  }

  const twoFactorSmsHandler = async () => {
    const result = await enableTwoFactorSmsAuth(userInfo.email);
    if (result.message === "Two Factor Enabled") {
      if (result.user.twoFactorSmsEnabled) {
        swal("Successful", "Two Factor Authentication enabled successfully", "success")
      } else {
        swal("Successful", "Two Factor Authentication disabled successfully", "success")
      }
      setSmsChecked(result.user.twoFactorSmsEnabled)
    } else {
      swal("Failed", "Something went wrong", "error")
    }
  }


  const twoFactorAuthenticatorHandler = async () => {
    const result = await enableTwoFactorAuthenticator(userInfo.email);
    if (result.message === "Two Factor Enabled") {
      setQrCodeEnabled({
        enabled: true,
        qrCode: result.qrcode
      })
    } else {
      setQrCodeEnabled({
        enabled: false,
        qrCode: ""
      })
      swal("Successful", "Two Factor Authentication disabled successfully", "success")
    }
    setAuthenticatorChecked(result.user.twoFactorAuthenticatorEnabled)
  }

  // done scan QR
  const doneQRScan = () => {
    swal("Successful", "QR Code generated successfully", "success")
      .then(value => {
        if (value) {
          history.go(0)
        }
      })
  }

  const newsletter = async () => {
    let result;
    if (data && data.newsletterSubscription) {
      result = await removeNewsletterSubscription(userInfo.email);
      swal("Successful", result.message, "success").then(value => {
        if (value) {
          history.go(0)
        }
      })
    } else if (data && !data.newsletterSubscription) {
      result = await addNewsletterSubscription(userInfo.email);
      swal("Successful", result.message, "success").then(value => {
        if (value) {
          history.go(0)
        }
      })
    }

    setCheckNewsletter(result && result.user && result.user.newsletterSubscription)

  }



  const uploadPhotoHandler = async (e) => {
    const file = e.target.files[0];
    const photo_url = await api.photoUpload(file)
    console.log(photo_url);
    await uploadPhoto(photo_url, userInfo._id)
    swal("Successfully", "Photo uploaded", "success").then(value => {
      if (value) {
        history.go(0)
      }
    })
  }

  useEffect(() => {
    if (data && userInfo) {
      setEmail(data.email)
      setContact(data.phn_no)
      setName(data.name)
      setOrgName(data.Org_name)
      setOrgEmail(data.Org_email)
      setOrgAddress(data.Org_office_address)
      setOrgPhnNo(data.Org_phn_no)
      setPhoto(data.photo_url)
      setPlan(data.current_plan)
    }
  }, [data, userInfo])


  useEffect(async () => {
    const purchaseDetails = await purchaseHistoryHelper(userInfo._id)
    setPurchaseHistory(purchaseDetails)
  }, [userInfo])


  const cancelSubscription = async () => {
    await cancelPlanSubscription(email, name, contact, plan)
    swal("Successfully", "A mail has been send to our customer service they will contact you soon", "success")
  }

  return {
    submitHandler,
    checked,
    twoFactorHandler,
    email,
    setEmail,
    name,
    setName,
    contact,
    setContact,
    orgName,
    setOrgName,
    orgEmail,
    setOrgEmail,
    orgAddress,
    setOrgAddress,
    orgPhnNo,
    setOrgPhnNo,
    photo,
    setPhoto,
    data,
    loading,
    error,
    deleteHandler,
    uploadPhotoHandler, smsChecked, setSmsChecked, twoFactorSmsHandler,
    purchaseHistory, newsletter, checkedNewsletter, cancelSubscription,
    authenticatorChecked,
    twoFactorAuthenticatorHandler,
    qrCodeEnabled,
    doneQRScan
  }
}
