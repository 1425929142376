import { useState } from 'react'

import { useSelector } from 'react-redux'
import { uploadText, creditCheckForText, creditReduce, grammerSuggestion } from './helper'

export const TextModalLogic = (history, setLoadingScan, setIsDisabled, isDisabled) => {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [counter, setCounter] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState([{
    originalWord: "",
    suggestedWords: []
  }]);

  const { userInfo } = useSelector((state) => state.userSignin)
  let creditsDetails

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleText = async (e) => {
    // empty suggested words
    setShowSuggestions([]);

    const count = e.target.value
    setText(e.target.value)
    const countWords = (count) => {
      if (count.length === 0) {
        return 0
      } else {
        count = count.replace(/(^\s*)|(\s*$)/gi, '')
        count = count.replace(/[ ]{2,}/gi, ' ')
        count = count.replace(/\n /, '\n')
        return count.split(' ').length
      }
    }

    setCounter(countWords(count))

    // Suggestion for grammer checking 
    let text = e.target.value;
    text = text.replace(/[^a-zA-Z ]/g, " ")
    text = text.toLowerCase();
    const textArr = text.split(" ");
    const length = textArr.length;
    if (length === 0) {
      setShowSuggestions([]);
    }
    console.log(textArr);
    textArr.forEach(async (word) => {
      if (word !== "") {
        const res = await grammerSuggestion(word);
        if (res.suggested_items.length !== 0) {
          setShowSuggestions((prevValue) => (
            [
              {
                originalWord: word,
                suggestedWords: res.suggested_items
              },
              ...prevValue
            ]))
        }
      } else {
        setShowSuggestions([])
        console.log("Contains empty string");
      }
    })

  }

  console.log(showSuggestions);

  const handleSubmit = async () => {
    setLoadingScan(true)

    creditsDetails = await creditCheckForText(text, userInfo._id)

    if (creditsDetails && creditsDetails.message != 'No Credits left') {
      setOpen(false)

      // text scan
      const textReport = await uploadText(text, userInfo._id)
      // credit reduce
      await creditReduce(counter, userInfo._id)
      console.log(textReport)

      if (textReport && textReport.reportId) {
        history.push(`/dashboard/scans/${textReport.reportId}`)
      }
    } else {
      setLoadingScan(false)
      setIsDisabled(true)
      setOpen(false)
    }
  }

  return {
    open,
    setOpen,
    text,
    setText,
    handleOpen,
    handleClose,
    handleText,
    handleSubmit,
    creditsDetails,
    counter,
    showSuggestions
  }
}
