export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED'

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAILED = 'USER_SIGNIN_FAILED'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST,'
export const USER_PROFILE_FETCH = 'USER_PROFILE_FETCH,'
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED,'
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE'
export const USER_PROFILE_UPDATE_FAILED = 'USER_PROFILE_UPDATE_FAILED'
export const USER_PROFILE_DELETE = 'USER_PROFILE_DELETE'
export const USER_PROFILE_DELETE_FAILED = 'USER_PROFILE_DELETE_FAILED'
