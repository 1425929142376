import React from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Container,
  Grid,
} from '@material-ui/core'

import StarIcon from '@material-ui/icons/StarBorder'

import { useStyles } from './pricingCard.style.js'
import { tiers } from './PricingTiers'
import WaveBg from '../../assets-new/Home/Purple Wave Bg Card.png';

const Pricing = () => {
  const classes = useStyles()

  const purchaseButton = () => {
    swal("Looks like you aren't signed in...", {
      buttons: {
        register: "Register",
        login: "Login"
      },
    })
    .then((value) => {
      // console.log("/"+value);
      window.location = ("/"+value);
    });
  };

  return (
    <>
      <div style={{position:"relative"}}>
        <div className={classes.gradientBg}></div>
        <Container>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              md={4} 
              className={classes.cardGrids}
            >
              <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center'}}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
              <Card elevation={7} className={classes.card}>
                <img src={WaveBg} style={{width: "100%"}}/>
                <div className={classes.cardPricing}>
                  <Typography className={classes.cardCredit} style={{textAlign: "center"}}>
                      {tier.credits}
                    </Typography>
                    <Typography component="h2" variant="h3"  className={classes.cardPrice}>
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" className={classes.cardPricePerMonth}>
                      /month
                    </Typography>
                  </div>
                <CardContent>
                  <Container>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                          className={classes.cardPlanFeatures}
                        >
                          ✓ {line}
                        </Typography>
                      ))}
                    </ul>
                  </Container>
                </CardContent>
                <CardActions>
                  {/* <Link to="/login" style={{ textDecoration: 'none', margin: 'auto' }}> */}
                    <Button
                      className={classes.cardButton}
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick= {purchaseButton}
                    >
                      {tier.buttonText}
                    </Button>
                  {/* </Link> */}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Container>
        
        
        <div className={classes.personalPlanCard}>
            <Typography className={classes.personalPlanHeading}>
            Want a custom plan made only for you? ✨
            </Typography>
            <Link to="/Contact-us" className={classes.personalPlanButton} style={{ textDecoration: 'none'}}>Contact Sales</Link>
        </div>
      </div>
    </>
  )
}

export default Pricing
