import React from 'react'
import Container from '@material-ui/core/Container'
import Getstarted from '../components/GetStarted/Getstarted'
import Footer from '../components/Footer'

function TandC() {
  return (
    <div>
      <Container maxWidth="md">
        <h1>Release Notes</h1>
        <ol>
          <li>
            <p>05/09/2021 - Launched for internal team</p>
            <ul>
                <li>User Interface</li>
                <li>Plagiarism Checking</li>
                <li>Direct content</li>  
                <li>Content from file</li>
                <li>Restricted Formats(.doc, .docx)</li>
                <li>Login and Registration</li>
                <li>Normal Login</li>
                <li>Social Login with Mandatory details filling</li>
                <li>Email link to validate account</li>
                <li>Forget Password Option - Recovery through the email link</li>
                <li>Direct Download</li>
            </ul>
          </li>
          <li>
            <p>06/09/2021 - Review Updates Started</p>
            <ul>
                <li>Multi-Language Support</li>
                <li>Downloadable PDF Report</li>
                <li>Highlighted plagiarised text</li>
                <li>Email Report</li>
                <li>Mobile Responsiveness</li>
                <li>Profile Section</li>
            </ul>
          </li>
          <li>
            <p>21/09/2021 - 1st Update</p>
            <ul>
            <li>Auto Logout if tab closed</li>
            <li>Remember me option</li>
            <li>Add 2FA Feature in both admin and normal user login system(for normal users keep it optional, for admin mandatory)</li>
            <li>Coming Soon Page</li>
            <li>Profile Section</li>
            <li>Chat Bot</li>
            <li>Admin Activity Log Creation</li>
            <li>Admin Notification System</li>
            <li>Unique Promo Code / Payment Link</li>
            </ul>
          </li>
          <li>
            <p>22/09/2021 - 2nd Update</p>
          </li>
          <li>
            <p>24/09/2021 - 3rd Update</p>
            <ul>
                <li>Mail Renderbit</li>
                <li>T&C, PP, and RN publish</li>
                <li>Package wise feature unlock</li>
                <li>Package wise more search result</li>
                <li>Create bug hunting section</li>
                <li>Create blog section</li>
                <li>Security Audit of both the domains</li>
            </ul>
          </li>
          <li>
            <p>27/09/2021 - 4th Update(Major Update)</p>
          </li>
          <li>
            <p>30/09/2021 - 5th Launch(Major Update) - Pre Launch Ready</p>
          </li>
          <li>
            <p>01/10/2021 - 6th Launch - Final Bug Fix and Updates</p>
          </li>
          <li>
            <p>02/10/2021 - Presentation and Internal Launch</p>
          </li>
          <li>
            <p>Tentative Final Launch Date - 02/10/2021</p>
          </li>     
        </ol>
        <br /> <br />
        <Getstarted />
      </Container>
      <Footer />
    </div>
  )
}

export default TandC
