import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  table: {
    boxShadow: '10px 10px 5px #0002',
  },
  headingFont: {
    fontSize: '1.1rem',
    textAlign: "center"
  },
  spacing: {
    margin: '10vh',
    background: '#fff0',
  },
  invisible: {
    textDecoration: 'none',
    background: '#fff0',
  },
  shadow: {
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    background: '#fff',
    fontSize: '1.2rem !important',
  },
}))
