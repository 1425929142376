import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export const twoFactorAuthenticatorHandler = async (email, authToken) => {
    const body = {
        email: email,
        token: authToken
    }

    const { data } = await axios.post(`${API}/users/enable-twoFA-authenticator/verify`, body, config)
    localStorage.setItem('userData', JSON.stringify(data));
    console.log(data)
    return data
}
