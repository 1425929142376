import React, { useContext, useEffect } from 'react'
import { FirebaseContext } from '../../firebase/firebase'
import {
  FacebookLoginButton,
  GoogleLoginButton,
  TwitterLoginButton,
} from 'react-social-login-buttons'
import { Grid, Divider, Typography } from '@material-ui/core'
import { useSelector } from "react-redux"
import swal from 'sweetalert'

import GoogleIcon from "../../assets-new/Google icon.svg";
import FacebookIcon from "../../assets-new/Facebook icon.svg";

function SocialLogin({ history }) {
  const { api } = useContext(FirebaseContext)

  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    if (userInfo) {
      if (userInfo && userInfo.twoFactorAuthenticatorEnabled) {
        history.push("/twoFactorAuthenticator");
      }
      else if (userInfo && userInfo.twoFactorEnabled || userInfo && userInfo.twoFactorSmsEnabled) {
        history.push("/twoFactorAuth");
      } else {
        history.push("/dashboard")
      }
    }
  }, [history, userInfo])

  const googleAuth = async () => {
    const errMsg = await api.googleSignIn()
    if (errMsg) {
      alert(errMsg)
    } else {
      swal("Successfull", "You will be logged in few seconds", "success")
    }
  }

  const facebookAuth = async () => {
    const errMsg = await api.facebookSignIn()
    if (errMsg) {
      alert(errMsg)
    } else {
      swal("Successfull", "You will be logged in few seconds", "success")
    }
  }

  const twitterAuth = async () => {
    const errMsg = await api.twitterSignIn()
    if (errMsg) {
      alert(errMsg)
    } else {
      swal("Successfull", "You will be logged in few seconds", "success")
    }
  }

  return (
    <div style={{ margin: '1rem 0' }}>
      <Divider variant="middle" flexItem />
      <Typography variant="subtitle2" color="primary" align="center">
        Or login with
      </Typography>

      <Grid container justify="center" style={{ width: "20vw", margin: "0.7vw" }}>
        <Grid item xs={2} align="center">
          <img src={GoogleIcon}
            onClick={googleAuth}
            align="center"
            type="light"
            style={{ width: '1.8vw', cursor: 'pointer' }}
          />
        </Grid>
        <Grid item xs={2} align="center">
          <img src={FacebookIcon} onClick={facebookAuth} align="center" style={{ width: "1.9vw", cursor: 'pointer' }} />
        </Grid>
        {/*<Grid item xs={12} md={6}>
          <TwitterLoginButton
            onClick={twitterAuth}
            iconSize='30px'
            align='center'
          />
        </Grid>
        */}
      </Grid>
    </div>
  )
}

export default SocialLogin
