import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  Grid,
  Typography,
  StepContent,
  StepLabel,
  Step,
  Stepper,
} from '@material-ui/core'

import { getSteps, getStepContent } from './stepsValues'
import { useStyles } from './Steps.style'
import Plag from '../../../assets/check.svg'
import Underline from '../../../assets-new/Home/Underline.png';
import LottieGif from '../../../assets-new/Home/lottie.gif';
import EasySectionPic from '../../../assets-new/Home/EasyPartImg.png';

export default function VerticalLinearStepper() {
  const classes = useStyles()
  const steps = getSteps()

  return (
    <>
      <div className={classes.whatWeDoSection}>
        <Typography className={classes.sectionHeading}>
          What we do differently
        </Typography>
        <img src={Underline} className={classes.sectionHeadingUnderline} alt="." />

        {/* 1st Section */}
        <Grid container className={classes.root} style={{ marginTop: "5vw" }}>
          <Grid md={6} style={{ marginTop: "0.5vw" }}>
            <Typography 
              data-aos="fade-right"
              data-aos-delay="150"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={classes.heading}>
              Checks against
              <br />
              10 billion+
              <span className={classes.headingBorderText}>sources</span>
            </Typography>
            <Typography 
              data-aos="fade-right"
              data-aos-delay="350"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={classes.description}
            >
              The document you provide is cross-checked against well over 10 billion sources to provide you the best
              results.
            </Typography>
            {/* <Link to="/" className={classes.learnMoreLink} style={{ textDecoration: 'none' }}>
              Learn more
            </Link> */}
          </Grid>
          <Grid md={6}>
            <img 
              data-aos="fade-left"
              data-aos-delay="350"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={classes.rightImg} 
              src={LottieGif} alt="Plagiarism" 
            />
          </Grid>
        </Grid>

        {/* 2nd Section */}
        <Grid container style={{ marginTop: "2vw", paddingBottom: "2vw" }}>
          <Grid md={6}>
            <img 
              data-aos="fade-right"
              data-aos-delay="350"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={classes.leftImg} 
              src={EasySectionPic} alt="Plagiarism" 
            />
          </Grid>
          <Grid md={6} className={classes.easySectionContentGrid}>
            <Typography 
              data-aos="fade-left"
              data-aos-delay="150"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={clsx(classes.heading, classes.easyHeading)}>
              Easy to interpret
              <span className={classes.headingBorderText} style={{ transform: "rotate(2.55deg)" }}>plagiarism score</span>
            </Typography>
            <Typography 
              data-aos="fade-left"
              data-aos-delay="350"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              className={clsx(classes.description, classes.easyDescription)}
            >
              The results we provide to you can be easily understood and helps you create your original content.
            </Typography>
            {/* <Link to="/" className={classes.learnMoreLink} style={{ textDecoration: 'none', left: "28.3vw" }}>
              Learn more
            </Link> */}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
