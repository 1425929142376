import {
    FETCH_RESULT_URL_REQUEST,
    FETCH_RESULT_URL_SUCCESS,
    FETCH_RESULT_URL_FAILED
} from '../constants'


export const resultUrlAction = (url) => async (dispatch) => {
    dispatch({
        type: FETCH_RESULT_URL_REQUEST,
    })

    try {
        console.log(url);
        dispatch({
            type: FETCH_RESULT_URL_SUCCESS,
            payload: url,
        })
    } catch (error) {
        dispatch({
            type: FETCH_RESULT_URL_FAILED,
            payload: error
        })
    }
}
