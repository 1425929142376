export const tiers = [
  {
    title: 'Personal',
    subHeader: '1 Credit',
    price: '2',
    description: [
      ' 25 Pages per Year',
      ' 6K Words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
    creditId: 1,
  },
  {
    title: 'Business',
    subHeader: '2 Credits',
    price: '9.41',
    description: [
      ' 1200 Pages per Year',
      ' 300K words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
    creditId: 2,
  },
  {
    title: 'Premium',
    subHeader: '3 Credits',
    price: '9.11',
    description: [
      ' 1400 Pages per Year',
      ' 400K words',
      'Scan text in more than 100 languages',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
    creditId: 3,
  }
]
