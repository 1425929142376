import { makeStyles } from '@material-ui/core/styles'
import BgPricing from '../../../assets/pricingTop.png'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(3, 0, 6),
  },

  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },

  cardHeader: {
    color: '#000',
    fontWeight: '900',
    fontSize: '8rem',
    backgroundColor: '#C7C4FB',
    background: `url(${BgPricing})`,
    backgroundSize: 'cover',
  },

  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(4),
  },

  cardButton: {
    width: '100%',
    marginLeft: '70%',
    padding: '0.8rem 2rem',
    marginBottom: theme.spacing(4),
  },

  personalPlanCard: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    margin: "2.5vw 0 3vw",
    width: "30vw",
    height: "10vw",
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px"
  },
  personalPlanHeading: {
    position: "relative",
    textAlign: "center",
    top: "2vw",
    color: "#5145FF",
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif"
  },
  personalPlanButton: {
    position: "relative",
    top: "4vw",
    left: "35%",
    transform: "translateX(-50%)",
    padding: "0.8vw 1.5vw",
    background: "linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)",
    color: "#fff",
    borderRadius: "45px",
    fontSize: "1vw",
    fontWeight: "semibold"
  },
}))

export { useStyles }
