import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';


import RegisterLogic from './register'
import SocialLogin from '../../../components/RegSocialAuth'

import BackgroundPic from "../../../assets-new/Login Bg.png";

export default function RegisterPage({ history }) {
  const {
    classes,
    RegisterSubmitHandler,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    loading,
    OrgName,
    setOrgName,
    number,
    setNumber,
    name,
    setName,
    confirmPassword,
    setConfirmPassword,
    isMobile,
    error,
  } = RegisterLogic(history)

  return (
    <>
      {loading && <LinearProgress variant="determinate" />}

      <div className={classes.paper}>
        <img src={BackgroundPic} className={classes.BgPic} />
        <Typography component="h1" variant="h3" align="center" className={classes.heading}>
          Register
        </Typography>
        <br />
        {error && <Alert severity="error" className={classes.error}>{error}</Alert>}

        <div className={classes.fullCard}>
          <form className={classes.form} noValidate>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Name"
              label="Full Name"
              name="name"
              autoFocus
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="number"
              label="Phone No."
              name="number"
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              label="Organisation Name"
              type="text"
              id="Registration Number"
              value={OrgName}
              onChange={(e) => setOrgName(e.target.value)}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              // variant="contained"
              color="primary"
              className={classes.submit}
              onClick={RegisterSubmitHandler}
            >
              Register
            </Button>
          </form>
          <SocialLogin history={history} />
        </div>

        <Grid container>
          <Grid item xs>
            <Typography align="center" variant="h6" className={classes.loginRedirect}>
              Have an account? &nbsp;
              <Link to="/Login">
                Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
