import React from 'react'
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import AOS from 'aos';
import 'aos/dist/aos.css';

import dataProcessing from '../../../assets/upload.svg';
import ScanGirlIllustration from "../../../assets-new/Home/Scan Section Pic.png";
import FreePic from "../../../assets-new/Home/Free.png";

import { useStyles } from './scanForm.style'
import { useTheme } from '@material-ui/core/styles'

//Validations
import * as yup from 'yup'
import { useFormik } from 'formik'

const ScanForm = () => {

  AOS.init({
    // Global settings:
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
  });

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object({
    text: yup.string().required('Some text is Required'),
  })

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      history.push('/register')
    },
  })

  return (
    <>
      <Grid container id="scanForm" className={classes.scanForm}>
        <Grid item md={4} className={classes.imageGrid}>
          <img 
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            className={classes.leftImage} 
            src={ScanGirlIllustration} 
            alt="Girl Scan" 
          />
        </Grid>
        <Grid item md={8} className={classes.formGrid}>
        <Typography 
          data-aos="fade-left"
          data-aos-delay="50"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          className={classes.heading}
        >
          <span className={classes.headingGradient} style={{marginLeft: "12.5vw"}}>Scan your </span> 
          <br/>
          <span className={classes.headingGradient}>Texts Now</span>
          <img src={FreePic} className={classes.freeImg} />
        </Typography>
        <Typography className={classes.description}>
          Get all your scans done with our plag checkers (First check free)
        </Typography>
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
              }}
              autoComplete="off"
            >
              <TextField
                data-aos="new-animation"
                spellCheck="true"
                className={classes.form}
                placeholder="Enter text to scan"
                multiline
                rows={7}
                id="text"
                InputProps={{ disableUnderline: true }}
                value={formik.values.text}
                onChange={formik.handleChange}
                error={formik.touched.text && Boolean(formik.errors.text)}
                helperText={formik.touched.text && formik.errors.text}
              />

              <Button type="submit" variant="contained" className={classes.submit}>
                Scan Now
              </Button>
            </form>
        </Grid>
      </Grid>
    </>
  )
}

export default ScanForm
