// config file

export default {
  apiKey: 'AIzaSyAYssrSI634VtrH4HxWwl5uxXWVTVp3qjE',
  authDomain: 'iemsecure.com',
  projectId: 'iem-fbe93',
  storageBucket: 'iem-fbe93.appspot.com',
  messagingSenderId: '230632233744',
  appId: '1:230632233744:web:f27fb35e5514163e5b2395',
  measurementId: 'G-XZD3N23JTK',
}
