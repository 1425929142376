import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box, Tab,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Breadcrumbs,
  FormControlLabel,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fade,
  Modal,
  Backdrop,
} from '@material-ui/core'

import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { useStyles, IOSSwitch } from './profile.style'

import { ProfileLogic } from './profile'

import ProfilePic from "../../../assets-new/user.png";

export default function Profile() {
  const classes = useStyles()

  const {

    submitHandler,
    checked,
    twoFactorHandler,
    email,
    setEmail,
    name,
    setName,
    contact,
    setContact,
    orgName,
    setOrgName,
    orgEmail,
    setOrgEmail,
    orgAddress,
    setOrgAddress,
    orgPhnNo,
    setOrgPhnNo,
    photo,
    setPhoto,
    data,
    loading,
    error,
    deleteHandler,
    uploadPhotoHandler, smsChecked, setSmsChecked, twoFactorSmsHandler,
    purchaseHistory, newsletter, checkedNewsletter, cancelSubscription,
    authenticatorChecked,
    twoFactorAuthenticatorHandler,
    qrCodeEnabled,
    doneQRScan
  } = ProfileLogic()

  // For Changing Tabs on click
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
          <strong>IEMSECURE</strong>{' '}
        </Link>
        <Typography>Profile</Typography>
      </Breadcrumbs>

      <Container>
        <img src={photo ? photo : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} className={classes.profilePic} />

        {/* Upload local files */}
        <form encType="multipart/form-data" action="">
          <input
            id="imageUploadButton"
            name="imageUploadButton"
            type="file"
            onChange={(event) => uploadPhotoHandler(event)}
            hidden
            multiple="false"
            accept=".jpg, .jpeg, .png"
          />
        </form>

        {/* Upload photo button */}
        <Paper elevation={6} className={classes.uploadBtn}>
          <label htmlFor={'imageUploadButton'}>
            <div style={{ cursor: 'pointer' }}>
              <Typography variant="subtitle1" align="center" className={classes.uploadPartIcon}>
                Upload Photo
              </Typography>
            </div>
          </label>
        </Paper>
        <Typography className={classes.profileName}>{name}</Typography>
        <Typography className={classes.profileCredits}>Credits left: {data && data.creditsLeft}</Typography>
      </Container>

      <Box className={classes.detailsBox}>
        <TabContext value={value}>
          {/* Tab Headings */}
          <Box className={classes.detailsBoxHeadingPart} align="center">
            <TabList className={classes.headingTabsList} onChange={handleChange} indicatorColor="primary" textColor="primary" aria-label="lab API tabs example">
              <Tab label={<Typography className={classes.headingTabs_heading}>Personal info</Typography>} value="1" className={classes.headingTabs} style={{ marginLeft: "1.5vw" }} />
              <Tab label={<Typography style={{ padding: "0 1.1vw", width: "17vw" }} className={classes.headingTabs_heading}>Organisational details</Typography>} value="2" className={classes.headingTabs} />
              <Tab label={<Typography className={classes.headingTabs_heading}>Purchase History</Typography>} value="3" className={classes.headingTabs} />
              <Tab label={<Typography className={classes.headingTabs_heading}>Settings</Typography>} value="4" className={classes.headingTabs} />
            </TabList>
          </Box>
          {/* Personal details */}
          <TabPanel value="1">
            <form>
              <TextField
                disabled
                id="outlined-disabled"
                label="Email address *"
                value={email}
                fullWidth
                defaultValue={true}
                className={classes.personalEmail}
              />
              <br />
              <TextField
                variant="standard"
                margin="normal"
                required
                focused
                fullWidth
                id="contact"
                label="Phone No."
                name="contact"
                autoComplete="contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                className={classes.personalPhone}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
              >
                Update
              </Button>
            </form>
          </TabPanel>

          {/* Organisational details */}
          <TabPanel value="2">
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="orgName"
              label="Organisation Name"
              name="orgName"
              focused
              autoComplete="orgName"
              autoFocus
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              className={classes.personalPhone}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="orgName"
              label="Organisation Email"
              name="orgEmail"
              focused
              autoComplete="orgEmail"
              autoFocus
              value={orgEmail}
              onChange={(e) => setOrgEmail(e.target.value)}
              className={classes.personalPhone}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="orgAddress"
              label="Office Address"
              name="orgAddress"
              focused
              autoComplete="orgAddress"
              autoFocus
              value={orgAddress}
              onChange={(e) => setOrgAddress(e.target.value)}
              className={classes.personalPhone}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="contact"
              label="Phone"
              name="contact"
              focused
              autoComplete="Phone"
              autoFocus
              value={orgPhnNo}
              onChange={(e) => setOrgPhnNo(e.target.value)}
              className={classes.personalPhone}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="gstin"
              label="GSTIN / TIN Number"
              name="gstin"
              focused
              autoComplete="GSTIN"
              autoFocus
              value={"orgGstinNo"}
              // onChange={}
              className={classes.personalPhone}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="panNo"
              label="Organisation PAN Number"
              name="panNo"
              focused
              autoComplete="orgPanNo"
              autoFocus
              value={"orgPanNo"}
              // onChange={}
              className={classes.personalPhone}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitHandler}
            >
              Update
            </Button>
          </TabPanel>

          {/* Purchase hostory */}
          <TabPanel value="3">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ fontWeight: "bold" }}>
                  <TableRow>
                    <TableCell>Plan Type</TableCell>
                    <TableCell align="left">Credits</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date & Time</TableCell>
                    <TableCell align="left">Recipt URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseHistory && purchaseHistory.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell align="left">{row.plan.plan_type}</TableCell>
                      <TableCell align="left">{row.plan.credit}</TableCell>
                      <TableCell align="left">{row.plan.amount}</TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell align="left">
                        <Button variant="outlined" color="primary" href={row.paymentResult.receipt_url}>
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </TabPanel>

          {/* Settings */}

          <TabPanel value="4">
            <FormControlLabel control={
              <Switch
                className={classes.switch}
                onChange={twoFactorHandler}
                checked={checked}
              />
            } label="Enable Two Factor Authentication via Email" />

            <FormControlLabel control={
              <Switch
                className={classes.switch}
                onChange={twoFactorSmsHandler}
                checked={smsChecked}
              />
            } label="Enable Two Factor Authentication via sms otp (be sure you have updated your profile with your phone number)" />

            <FormControlLabel control={
              <Switch
                className={classes.switch}
                onChange={twoFactorAuthenticatorHandler}
                checked={authenticatorChecked}
              />
            } label="Enable Two Factor Authentication via Google Authenticator" />

            <FormControlLabel control={
              <Switch
                className={classes.switch}
                onChange={newsletter}
                checked={checkedNewsletter}
              />
            } label="Subscribe to our newsletter & stay updated on the latest developments and special offer announcements." />
            <br /><br />

            <Button variant='outlined' onClick={cancelSubscription}>
              Cancel Subscription
            </Button>
          </TabPanel>
        </TabContext>
      </Box>

      {/* Modal for viewing QR code (only if 2FA valid for Authenticator 2FA) */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={qrCodeEnabled.enabled}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <Fade in={qrCodeEnabled.enabled}>
          <div className={classes.paperQR}>
            <img src={qrCodeEnabled && qrCodeEnabled.qrCode} />
            <br/>
            <br/>
            <Button fullWidth variant="contained" color="secondary" onClick={doneQRScan}>Done</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
