import { Link } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';

import { Container,Button, Grid, Typography } from '@material-ui/core';
//Assets
import { useStyles } from './hero.style';
import HeroImage from '../../../assets-new/Home/Hero Img.png';
import LaptopGirl from '../../../assets-new/Home/LaptopGirl.png';

const HeroSection = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="xl">
    <Grid container justify="space-around" maxWidth="false" className={classes.scanFormGridContainer}>
      <Grid item md={6} className={classes.leftGrid}>
        <Typography variant="h3" component="h2" className={clsx(classes.heroHeading, classes.headingGradient)}>
            <span className={classes.iemsecureHeading}>IEMSecure</span>
            <br/>
            <span className={classes.iemsecureHeading} style={{animationDelay: "3.3s"}}>Plagiarism Checker</span>
        </Typography>
        <Typography className={classes.leftPara} variant="h6" component="p">
        Welcome to IEMSECURE - a prominent online plagiarism checker that can assist students, instructors, schools, colleges, universities, and even professionals verify their works for plagiarism and duplicate material utilising its strong features.
        </Typography>
        <Link to="/register" style={{textDecoration: 'none'}}>
          <Button
            type="submit"
            className={clsx(classes.gradientBtn, classes.actionBtn)}
          >
            Get started
          </Button>
        </Link>
        <a href="#scanForm" style={{textDecoration: 'none'}}>
          <Button
            type="submit"
            className={clsx(classes.gradientBorderBtn, classes.actionBtn)}
          >
            Scan Now
          </Button>
        </a>
      </Grid>

      <Grid item md={6}>
        <img className={clsx(classes.heroImg, classes.iemsecureHeading)} style={{animationDelay: "4.2s"}} src={HeroImage} alt="hero img" />
        <img className={classes.mobileLaptopGirlImg} src={LaptopGirl} alt="LaptopGirl"/>
      </Grid>
    </Grid>
    </Container>
  )
}

export default HeroSection;
