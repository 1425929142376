import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import clsx from 'clsx'
import {
  Drawer,
  AppBar,
  Button,
  Toolbar,
  List,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  useMediaQuery,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

import NavLogo from "../../assets-new/NavLogo.svg";

import { useTheme } from '@material-ui/core/styles'
import { useStyles } from './style'

import { mainListItems } from './listItems'
import { DashboardNavLogic } from './DashboardNav'

const DashboardNav = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const history = useHistory()
  const {
    toggle,
    data,
    setToggle,
    open,
    userInfo,
    handleMenu,
    handleClose,
    handelLogout,
    anchorEl,
  } = DashboardNavLogic(history)

  return (
    <>
      <AppBar position="fixed" color="secondary" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography
            component="h5"
            variant="h5"
            color="primary"
            noWrap
            className={classes.title}
          >
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={() => setToggle(true)}
              color="primary"
              aria-label="menu"
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img src={NavLogo} className={classes.navLogo}/>
            </Link>
          </Typography>

          {/*Credit left*/}
          {isMobile ? (
            <Typography
              variant="h6"
              color="primary"
              align="center"
              style={{ marginRight: '5rem' }}
            >
              <Button className={classes.navCreditBtn}>
                {' '}
                Credit Left : {data && data.creditsLeft}{' '}
              </Button>
            </Typography>
          ) : (
            ''
          )}

          {/* Profile view */}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
            className={classes.navProfileName}
          >
            <AccountCircle fontSize="large"/>
            &nbsp; {userInfo && userInfo.name}
          </IconButton>
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
            style={{ marginTop: '2.5rem', marginLeft: '-3rem'}}
          >
            <Link to="/dashboard/profile" style={{ textDecoration: 'none' }}>
              <MenuItem onClick={handleClose} className={classes.menu}>
                Profile
              </MenuItem>
            </Link>
            <Link to="/dashboard/scans" style={{ textDecoration: 'none' }}>
              <MenuItem onClick={handleClose}>Scans</MenuItem>
            </Link>
            <MenuItem onClick={handelLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/*Side Navbar*/}
      {isMobile ? (
        <>
          {/*Normal view*/}
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper),
            }}
            open={open}
          >
            <List style={{ paddingLeft: '15px' }}>{mainListItems}</List>
          </Drawer>
        </>
      ) : (
        <>
          {/*///      FOR MOBILE VIEW    ///*/}
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={toggle}
            onClose={() => setToggle(false)}
          >
            <List>
              {mainListItems}

              <Typography
                variant="h6"
                color="primary"
                align="center"
                style={{ marginRight: '1.2rem' }}
              >
                <Button variant="outlined">
                  {' '}
                  Credit Left : {data && data.creditsLeft}{' '}
                </Button>
              </Typography>
            </List>
          </Drawer>
        </>
      )}
    </>
  )
}

export default DashboardNav
