// firebase.js
// contains the Firebase context and provider

import React, { createContext } from 'react'
import firebaseConfig from './firebaseConfig'
import app from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'

// redux
import { useDispatch } from 'react-redux'
import { addUser, signInUser, signUpSocialUser, signInSocialUser } from '../redux/actions/userAction'
import swal from 'sweetalert'

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null)
export { FirebaseContext }

export default ({ children }) => {
  let firebase = {
    app: null,
    auth: null,
    storage: null,
  }

  const dispatch = useDispatch()
  const API = process.env.REACT_APP_API

  // check if firebase app has been initialized previously
  // if not, initialize with the config we saved earlier
  if (!app.apps.length) {
    app.initializeApp(firebaseConfig)
    firebase = {
      app: app,
      auth: app.auth(),
      storage: app.storage(),
      api: {
        signupUser,
        signinUser,
        googleSignUp,
        googleSignIn,
        facebookSignUp,
        facebookSignIn,
        twitterSignUp,
        twitterSignIn,
        fileUpload,
        photoUpload,
        fileDelete,
        resetPasswordEmail,
      },
    }
  }

  // function for signup, login and google auth

  // manual signup
  function signupUser(email, password, name, number, orgname) {
    return firebase.auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user
        console.log(user)

        // send verification mail
        firebase.auth.currentUser.sendEmailVerification().then(() => {
          // Email verification sent!

          // get token
          firebase.auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              // Send token and other details to your backend via HTTPS
              dispatch(addUser(idToken, name, number, orgname, API))
              swal("Success", 'Send Verification Mail', "success")
            })
            .catch(function (error) {
              // Handle error
              console.log(error)
              alert(error.message)
            })
        })
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            return `Email address already in use.`
          case 'auth/invalid-email':
            return `Email address is invalid.`
          case 'auth/operation-not-allowed':
            return `Error during sign up.`
          case 'auth/weak-password':
            return 'Password is not strong enough. Add additional characters including special characters and numbers.'
          default:
            return error.message
        }
      })
  }

  // Manual User Signin
  function signinUser(email, password, rememberMe) {
    var msg = ''
    return firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        const currUser = firebase.auth.currentUser

        console.log(currUser)

        // check if email is verified
        if (currUser.emailVerified) {
          var user = userCredential.user
          console.log(user)
          dispatch(signInUser({ email: user.email }, API, rememberMe))
        } else {
          return firebase.auth
            .signOut()
            .then(() => {
              // Sign-out successful.
              console.log('Signed Out as User is not Verified')
              return 'Signed out as User is not Verified'
            })
            .catch((error) => {
              // An error happened.
            })
        }
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        console.log({ err: errorCode, message: errorMessage })
        msg = error.message
        return msg
      })
  }

  // Oauth with google SignUp
  function googleSignUp() {
    var provider = new app.auth.GoogleAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signUpSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }



  // Oauth with google SignIn
  function googleSignIn() {
    var provider = new app.auth.GoogleAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signInSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }


  // Oauth with Facebook Sign up
  function facebookSignUp() {
    var provider = new app.auth.FacebookAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signUpSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }


  // Oauth with Facebook Sign in
  function facebookSignIn() {
    var provider = new app.auth.FacebookAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signInSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }


  //oauth with twitter Sign Up
  function twitterSignUp() {
    var provider = new app.auth.TwitterAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signUpSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }


  //oauth with twitter Sign In
  function twitterSignIn() {
    var provider = new app.auth.TwitterAuthProvider()
    return firebase.auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential
        console.log(credential)

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log(token)

        // The signed-in user info.
        var user = result.user
        console.log(user)

        // get token
        firebase.auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token and other details to your backend via HTTPS
            dispatch(signInSocialUser(idToken, API))
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message

        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        var email = error.email
        var credential = error.credential

        console.log({
          err: errorCode,
          message: errorMessage,
          email: email,
          credential: credential,
        })
        return errorMessage
      })
  }

  let counter = 0

  // upload file
  function fileUpload(file, fileType) {
    console.log(file)
    const fileName = 'uploaded'
    console.log(fileName)

    const metaData = {
      contentType: fileType
    }

    // ref
    const storageRef = firebase.storage.ref()
    const uploadTask = storageRef
      .child(`files/` + fileName + counter++ + '.docx')
      .put(file, metaData)

    // Handle successful uploads on complete
    return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      console.log('File available at', downloadURL)
      console.log(downloadURL)
      return downloadURL
    })
  }

  //Deleting files
  function fileDelete(fileName) {
    const storageRef = firebase.storage.ref()
    const deleteTask = storageRef.child(`files/${fileName}`)

    // Delete the file
    deleteTask
      .delete()
      .then(() => {
        // File deleted successfully
        console.log('File deleted successfully')
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log('File delete unsuccessful')
      })
  }


  // upload photo
  function photoUpload(file) {
    console.log(file)

    // ref
    const storageRef = firebase.storage.ref()

    const uploadTask = storageRef
      .child(`photos/` + file.name)
      .put(file)

    // Handle successful uploads on complete
    return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      console.log('File available at', downloadURL)
      console.log(downloadURL)
      return downloadURL
    })
  }

  // Reset Password Email
  function resetPasswordEmail(email) {
    return firebase.auth
      .sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        alert(`email sent for password reset`)
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        console.log(errorMessage)
        return errorMessage
      })
  }

  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>
}
