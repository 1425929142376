import { useState, useEffect } from 'react'
import { useContext } from 'react'
import swal from 'sweetalert';
import { FirebaseContext } from '../../../firebase/firebase'

import { useDispatch, useSelector } from 'react-redux'
import { userProfile } from '../../../redux/actions/userProfile'

import { useStyles } from './newScan.style'
import { uploadFile, creditCheckForFile, creditReduce } from './helper'

export const NewScanLogic = (history) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(false)
  const [fileName, setFileName] = useState('')
  const [uploadedFile, setUploadedFile] = useState('')
  const [loadingScan, setLoadingScan] = useState(false)
  // let creditsDetails
  const [creditsDetails, setCreditsDetails] = useState(null);
  const [isDisabledForCredit, setIsDisabledForCredit] = useState(null);

  const { api } = useContext(FirebaseContext)

  // selector
  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    if (userInfo) {
      dispatch(userProfile(userInfo && userInfo._id))
    } else {
      history.push("/login")
    }
  }, [dispatch, userInfo, creditsDetails])

  //store File
  const handleFile = async (e) => {
    setCreditsDetails(null)
    setIsDisabledForCredit(null)
    const file = e.target.files[0]
    setFileName(file.name)
    console.log(file)

    // var reader = new FileReader()
    // reader.readAsDataURL(file)
    // reader.onload = async function () {
    //   const base64 = reader.result.substring(reader.result.indexOf('base64') + 7)
    //   console.log(base64)

    //upload file to firebase
    const fileLink = await api.fileUpload(file, file.type)
    console.log(fileLink)
    setUploadedFile(fileLink)
    setIsDisabledForCredit(true);

    // credit check for file upload
    let credits = await creditCheckForFile(fileLink, userInfo._id)
    setIsDisabledForCredit(false)
    setCreditsDetails(credits)
    console.log(credits && credits.message)
    // }
    // reader.onerror = function (error) {
    //   console.log('Error: ', error)
    // }
  }

  //Delete file from cloud
  const deleteFile = (e) => {
    setFileName('')
    setUploadedFile('')
    setCreditsDetails(null)
    history.go(0);
  }

  const scan = async (e) => {
    if (!uploadedFile) {
      swal('No file provided for scan')
      return
    } else {
      setLoadingScan(true)
    }

    if (creditsDetails && creditsDetails.message !== 'No Credits left' && uploadedFile) {
      console.log(uploadedFile)
      const fileReport = await uploadFile(uploadedFile, userInfo._id)

      // credit reduce
      await creditReduce(creditsDetails.wordsCount, userInfo._id)

      if (fileReport && fileReport.reportId) {
        history.push(`/dashboard/scans/${fileReport.reportId}`)
      }
    } else {
      setLoadingScan(false)
      setIsDisabled(true)
    }
  }

  return {
    classes,
    isDisabled,
    setIsDisabled,
    fileName,
    loadingScan,
    setLoadingScan,
    handleFile,
    deleteFile,
    scan,
    creditsDetails,
    isDisabledForCredit,
    uploadedFile,
    userInfo,
  }
}
