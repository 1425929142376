import { makeStyles, styled } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%', // Fix IE 11 issue.
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(10, 20),

  },
  
  paperQR: {
    backgroundColor: '#3e35c1f2',
    padding: theme.spacing(3, 4, 3),
    borderRadius: "10%",
    boxShadow: theme.shadows[9]
  },

  content: {
    width: '100%',
  },

  display: {
    display: 'none',
  },
  paper: {
    margin: theme.spacing(6, 12),
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: "1vw",
    marginTop: "1vw",
    color: '#fff',
    width: "13vw",
    display: "block",
  },
  profilePic: {
    width: "9.5vw",
    height: "9.5vw",
    margin: "1vw 0 0 0vw",
    borderRadius: "50%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    // backgroundImage: "url('https://images.pexels.com/photos/1043471/pexels-photo-1043471.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')",
    backgroundSize: 'cover',
    minWidth: "9.5vw",
    minHeight: "9.5vw"
  },
  profileName: {
    textTransform: 'none',
    fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
    fontSize: "2.1vw",
    fontWeight: "bold",
    color: "#333",
    marginTop: "0.8vw",
    textAlign: "center",
  },
  profileCredits: {
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    fontSize: "1.1vw",
    fontWeight: "600",
    color: "#5145ff",
    margin: "0.4vw 0 2.6vw 0vw",
    textAlign: "center",
  },
  uploadBtn: {
    width: "13vw",
    padding: '0.8rem 0.8rem',
    marginLeft: "50%",
    marginTop: "1.4rem",
    marginBottom: "1rem",
    transform: "translateX(-50%)",
    cursor: 'pointer !important',
    borderRadius: "17px",
    backgroundColor: "#5045ff"
  },
  uploadPartIcon: {
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat',sans-serif",
    color: "#fff",
    fontSize: '1.2vw',
    lineHeight: '1.6vw'
  },
  detailsBox: {
    typography: 'body1',
    backgroundColor: '#fff',
    width: "70%",
    marginBottom: "5vw",
    borderRadius: "17px",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)"
  },
  detailsBoxHeadingPart: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  headingTabsList: {
    marginLeft: "0vw"
  },
  headingTabs: {
    margin: "0 0.6vw",
    marginTop: "1vw",
    backgroundColor: "#F8F8FF"
  },
  headingTabs_heading: {
    textTransform: 'none',
    fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
    fontSize: "1.1vw",
    fontWeight: "bold",
    color: "#333"
  },
  personalEmail: {
    fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
    fontSize: "1.1vw",
    fontWeight: "500",
    color: "#000",
    marginLeft: "1vw",
    width: "50%",
    "& .MuiFormLabel-root": {
      fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
      fontSize: "1.14vw",
      fontWeight: "500",
      color: "#000",
      marginBottom: "0.5vw",
    }
  },
  personalPhone: {
    fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
    fontSize: "1.14vw",
    fontWeight: "500",
    color: "#000",
    marginLeft: "1vw",
    width: "50%",
    "& .MuiFormLabel-root": {
      fontFamily: "'ITC Avant Garde Gothic Std Demi', 'Montserrat', sans-serif",
      fontSize: "1.1vw",
      fontWeight: "500",
      color: "#000",
      marginBottom: "0.5vw",
    }
  },
  switch: {
    width: 36,
    height: 20,
    padding: 0,
    marginLeft: "1vw",
    marginRight: 12,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 1,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }
}))



export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginRight: 12,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

}));
