import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Container, LinearProgress, Paper, Typography, Breadcrumbs, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { useStyles } from './scanlist.style'

import { ScanListLogic } from './scanList'

function ScanList() {
  const classes = useStyles()
  const { loading, data } = ScanListLogic()
  return (
    <>
      {loading ? <LinearProgress /> : <></>}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to="/dashboard" style={{ textDecoration: 'none' }}>
          <strong>IEMSECURE</strong>
        </Link>
        <Typography color="textPrimary">Your Scans</Typography>
      </Breadcrumbs>

      <Container>
        <Paper elevation={0} className={classes.spacing}>
          <TableContainer style={{ borderRadius: '15px' }}>
            <Table className={classes.table} size="medium" padding="normal">
              <TableHead className={classes.shadow}>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" className={classes.headingFont}>
                      Id{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" className={classes.headingFont}>
                      Name{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" className={classes.headingFont}>
                      Date Scanned{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" className={classes.headingFont}>
                      Similarity Score{' '}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" className={classes.headingFont}>
                      See Report{' '}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.shadow}>
                {/*Map data from here*/}
                {data &&
                  data.reverse().map((report) => (
                    <TableRow hover key={report._id} elevation={6}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" style={{ textAlign: "center" }}>{report._id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" style={{ textAlign: "center" }}>{report.fileName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" style={{ textAlign: "center" }}>
                          {report.createdAt.substring(0, 10)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" style={{ textAlign: "center" }}>
                          {report && report.similarityPercent == "NaN" ? ("0 %") : (<>{report && report.similarityPercent}%</>)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/dashboard/scans/${report._id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              textDecoration: 'none',
                              borderRadius: '8px',
                              padding: '0.5rem 1rem',
                              textTransform: "none",
                              fontSize: "1vw"
                            }}
                            size="medium"
                          >
                            More info
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  )
}

export default ScanList