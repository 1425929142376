import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { twoFactorAuthHandler } from './helper'
import swal from "sweetalert"

const TwoFactorAuth = (history) => {
    const { userInfo } = useSelector((state) => state.userSignin)

    const [authCode, setAuthCode] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault();

        const result = await twoFactorAuthHandler(userInfo.email, authCode);

        if (result.message === "Invalid OTP") {
            swal("Oops", "Invalid OTP", "error")
        } else {
            swal("Successfully", "Logged In", "success")
            history.push('/dashboard')
        }
    }
    return { submitHandler, authCode, setAuthCode }
}

export default TwoFactorAuth
