import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Getstarted from '../components/GetStarted/Getstarted'
import Footer from '../components/Footer'

function Plag() {
  return (
    <>
      <Container maxWidth="md">
        <Typography align="center" variant="h2">
          Frequently asked questions
        </Typography>
        <br />
        <br />

        <h2>What is IEMSECURE?</h2>
        <p>
          IEMSECURE is a leading website for checking plagiarism. This site helps you detect
          plagiarism in the content your content you provide. This site will also help you detect sites that
          have either copied content from your site without permission and also sites that have quoted
          your content.
        </p>
        <h2>What is IEMSECURE used for?</h2>
        <p>
          IEMSECURE can be used to check for plagiarism on your company&#39;s website, online
          magazine, blog, articles, or any other useful online content. You can learn how others are
          effectively using
        </p>
        <h2>How will I use IEMSECURE?</h2>
        <p>
          It's quite simple to use the free IEMSECURE service. You can paste your content or
          upload a document containing the content you want to check for plagiarism. You can start the
          checking and your job is done, IEMSECURE will check the content for plagiarism and return the
          result. The result will also be available as a pdf.
        </p>

        <h2>Is my content plagiarized if it matches the IEMSECURE search?</h2>
        <p>
          The IEMSECURE plagiarism checker looks for identical information online, but it cannot
          detect or comment on which of them is original whether the content was copied from another
          page. In the case where the match is found, one of the pages may have plagiarized content, or
          one of the pages is quoting another, or both of them have been copying from the same external
          source. It is also possible that they both are present by chance. The user has to verify the
          similarities and take appropriate answers.
        </p>
        <h2>What are the terms and conditions of use?</h2>
        <p>
          To view the official Terms &amp; Conditions of Use, please click here. Please read the terms
          and conditions carefully before use and use IEMSECURE at your own risk.
        </p>
        <h2>How much does IEMSECURE Premium cost??</h2>
        <p>
          Please go through the pricing page of IEMSECURE to get the details of pricing related to
          the services.
        </p>
        <h2>Is there any free subscription for IEMSECURE?</h2>
        <p>
          After you create your account in IEMSECURE, you will be given10 credits for free every
          month. These credits are offered as a free trial to the users. On exhaustion of the credits, you
          need to buy credits according to the pricing given on the payment page.
        </p>
        <h2>Which language does IEMSECURE work with?</h2>
        <p>
          IEMSECURE can be used for checking all widely used languages except those which use
          ideograms.
        </p>
        <br />
        <br />
        <Getstarted />
      </Container>

      <Footer />
    </>
  )
}

export default Plag
