import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  headingGradient: {
    backgroundImage: 'linear-gradient(90deg, #5145FF, #9A3CFD)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  gradientBtn: {
    background: 'linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)',
    borderRadius: '68px',
    padding: '0.58rem 2.5rem',
    color: '#fff',
  },
  gradientBorderBtn: {
    border: 'solid 3.5px transparent',
    backgroundImage: 'linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)',
    boxShadow: '2px 1000px 1px #fff inset',
    borderRadius: '68px',
    color: '#000',
    padding: '0.38rem 2.2rem',
    marginLeft: '2.3vw',
  },
  scanFormGridContainer: {
    marginTop: '-4rem',
  },
  leftGrid: {
    position: 'relative',
    left: '5.7vw',
    top: '0vw',
    [theme.breakpoints.down('sm')]: {
      left: '0',
      top: '16vw',
    },
  },
  leftPara: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    color: '#000',
    position: 'relative',
    left: '0.3vw',
    width: '30vw',
    top: '0.8vw',
    //animation
    animation: '$leftParaAnimation 0.5s ease-in',
    animationDelay: '4.2s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    opacity: '0',
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
    },
  },
  '@keyframes leftParaAnimation': {
    from: { opacity: '0' },
    to: { opacity: '1' },
  },
  heroHeading: {
    fontFamily: "'Favorit', 'Montserrat', sans-serif",
    fontSize: '3.75vw',
    fontWeight: '600',
    width: '40vw',
    marginTop: '8vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8.75vw',
      width: '85vw',
      marginTop: '0vw',
    },
  },
  iemsecureHeading: {
    backgroundImage: 'linear-gradient(90deg, #5145FF, #9A3CFD)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: '$iemHeadingAnimation 0.5s ease-in',
    animationDelay: '3.1s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    opacity: '0',
  },
  '@keyframes iemHeadingAnimation': {
    from: {
      position: 'relative',
      top: '2vw',
      opacity: '0',
    },
    to: {
      position: 'relative',
      top: '0',
      opacity: '1',
    },
  },

  heroImg: {
    width: '96%',
    position: 'relative',
    top: '0.5vw',
    left: '0.4vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileLaptopGirlImg: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '96%',
      position: 'relative',
      marginTop: '28.5vw',
    },
  },
  actionBtn: {
    fontSize: '1.2vw',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textTransform: 'none',
    position: 'relative',
    top: '2vw',
    animation: '$buttonAnimation 0.5s ease-in',
    animationDelay: '4.2s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    opacity: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.8vw',
    },
  },
  '@keyframes buttonAnimation': {
    from: { opacity: '0' },
    to: { opacity: '1' },
  },
}))
