import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Grid, Button } from '@material-ui/core'

import { useStyles } from './GetStarted.style'

function Getstarted() {
  const classes = useStyles()
  return (
    <div>
      <Grid container className={classes.getStarted}>
        <Grid item sm={8}>
          <Typography component="h4" variant="h5">
            Ready To Get Started?
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Link to="/Register" style={{ textDecoration: 'none', color: '#fff' }}>
            <Button variant="outlined" className={classes.actionBtn}>
              Register
            </Button>
          </Link>
          <Link to="/Login" style={{ textDecoration: 'none', color: '#fff' }}>
            <Button variant="outlined" className={classes.actionBtn}>
              Login
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Getstarted
