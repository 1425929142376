import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import swal from 'sweetalert'
import { userProfile } from '../../../redux/actions/userProfile'
import { makePaymentHelper } from './helper'

export const PricingLogic = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
  }, [history, userInfo])

  useEffect(() => {
    dispatch(userProfile(userInfo._id))
  }, [history, userInfo, dispatch])

  const makePayment = async (token, creditId) => {
    try {
      const data = await makePaymentHelper(token, userInfo, creditId)
      console.log(data)
      if (data) {
        swal("Success", 'Transaction completed by ' + userInfo.name + ' to IEMSECURE', "success").then(value => {
          if(value){
            history.go(0)
          }
        })
        
      }
    } catch (error) { }
  }

  return { makePayment, userInfo }
}
