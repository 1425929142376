import { Container, Grid, Typography, TextField, Button } from '@material-ui/core'

import { useStyles } from './ContactUs.style'

import Home from '../../assets/Contact.png'

import { Contactlogic } from './contact'
import Footer from '../../components/Footer'

//name, email, number, body

function Contact() {
  const classes = useStyles()
  const {
    log,
    name,
    setName,
    email,
    setEmail,
    body,
    setBody,
    number,
    setNumber,
    handelSubmit,
  } = Contactlogic()
  return (
    <>
      <Container>
        <Typography align="center" variant="h3">
          Contact Us
        </Typography>
        <Typography align="center" variant="body1">
          If you have any inquiry related to our services below, fill out our contact form
          so that we can reach you faster.
        </Typography>
        <br />
        <Grid container>
          <Grid item md>
            <Container>
              <img src={Home} className={classes.root} alt="Contact" />
              <Typography align="left">
                <b>Phone Number:</b> +1 (888) 607-0014 / +1 (206) 317 1731
                <br />
                <b>Address:</b> 6408 Elizabeth Avenue SE, Auburn WA 98092, USA
              </Typography>
            </Container>
          </Grid>
          <Grid item md={6} xs={12}>
            {/*Contact Form*/}

            <Container>
              {log ? (
                <Typography align="center" color="primary">
                  Thank you for submitting form one of our representive will contact you
                  soon
                </Typography>
              ) : (
                <></>
              )}
              <br />
              <TextField
                label="Contact Name"
                id="margin-none"
                className={classes.textField}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <br />
              <TextField
                label="Email address"
                id="margin-none"
                className={classes.textField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Contact number"
                type="number"
                id="margin-none"
                className={classes.textField}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              <br /> <br />
              <TextField
                placeholder="How can we help?."
                multiline
                rows={18}
                rowsMax={12}
                variant="filled"
                className={classes.textField}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
              <br />
              <br />
              <Button variant="contained" color="primary" onClick={handelSubmit}>
                Submit
              </Button>
            </Container>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  )
}

export default Contact
