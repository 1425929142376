import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILED,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILED,
  USER_LOGOUT,
} from '../constants/userConstants'

///    SIGNUP REDUCER    ///
export const userSignupReducer = (
  state = { loading: false, userInfo: null, error: null },
  action
) => {
  switch (action.type) {
    case USER_SIGNUP_REQUEST:
      return { loading: true }
    case USER_SIGNUP_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      }
    case USER_SIGNUP_FAILED:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

///    SIGNIN REDUCER    ///
export const userSigninReducer = (
  state = {
    loading: false,
    userInfo: null,
    isAuthenticated: false,
  },
  action
) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return {
        loading: true,
      }
    case USER_SIGNIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      }
    case USER_SIGNIN_FAILED:
      return {
        loading: false,
        userInfo: null,
        error: action.payload,
      }

    case USER_LOGOUT:
      return {
        userInfo: null,
      }
    default:
      return state
  }
}
