import { useState, useEffect } from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/actions/userAction'
import { userProfile } from '../../redux/actions/userProfile'
export const DashboardNavLogic = (history) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [toggle, setToggle] = useState(false)

  const open = Boolean(anchorEl)

  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.userSignin)

  useEffect(() => {
    dispatch(userProfile(userInfo && userInfo._id))
  }, [history, userInfo, dispatch])

  const { data } = useSelector((state) => state.userProfile)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handelLogout = () => {
    setAnchorEl(null)
    dispatch(logoutUser())
    history.push('/')
  }

  return {
    toggle,
    setToggle,
    open,
    userInfo,
    handleMenu,
    handleClose,
    handelLogout,
    data,
  }
}
