import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export const twoFactorAuthHandler = async (email, authCode) => {
    const body = {
        email: email,
        authCode: authCode
    }

    const { data } = await axios.post(`${API}/users/twoFA`, body, config)
    localStorage.setItem('userData', JSON.stringify(data));
    console.log(data)
    return data
}
