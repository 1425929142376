import React from 'react'
import { Link } from 'react-router-dom'
import { Divider, Grid, ListItem, ListItemText, Paper, Typography, Breadcrumbs, Container, CircularProgress, Button } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Skeleton } from '@material-ui/lab'

import { useStyles } from './scanResults.style'

import { ScanResultsLogic, CircularProgressWithLabel } from './scanResults'
import ResultPdf from '../../../components/Dashboard/ResultPdf'


var resultArray = new Set()

function ScanResults() {

  //Getting logic data
  const { data, userInfo, scanId, HoursAgo, MinutesAgo, SecondsAgo, DaysAgo, progress, redirectCompareText } = ScanResultsLogic(resultArray)
  var result = Array.from(resultArray)
  const classes = useStyles()

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className={classes.breadcrumbs}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
          <strong>IEMSECURE</strong>{' '}
        </Link>
        <Link color="inherit" to="/dashboard/scans" style={{ textDecoration: 'none' }}>
          <Typography color="textPrimary">Your Scans</Typography>
        </Link>

        <Typography color="textPrimary" variant="subtitle1">
          Scan Result
        </Typography>
      </Breadcrumbs>
      {/*Contains all the elements in the page*/}
      <Container>
        <br />
        <Typography
          component="h1"
          variant="h4"
          align="center"
          className={classes.sectionHeading}
        >
          Scan Report
        </Typography>
        {/*Scan Properties*/}
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs>
              <Typography align="center" variant="h5" className={classes.checkHeading}>
                DONE
              </Typography>
              <br />
              {data && data.status === 'running' || data === undefined ?
                <>
                  <Typography align="center">
                    <CircularProgressWithLabel value={progress} />
                  </Typography>
                </>
                :
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.checkResult}
                  style={{ fontSize: '1.05vw' }}
                >
                  Scanned{' '}
                  {data && (DaysAgo(data) === 0
                    ? HoursAgo(data) === 0
                      ? MinutesAgo(data) === 0
                        ? SecondsAgo(data) + ' seconds ago'
                        : MinutesAgo(data) + ' minutes ago'
                      : HoursAgo(data) + ' hours ago'
                    : DaysAgo(data) + `Days ago`)}
                </Typography>
              }

            </Grid>
            <Grid item xs>
              <Typography align="center" variant="h5" className={classes.checkHeading}>
                Results Found
              </Typography>
              <br />
              {data && data.results === undefined || data && data.status === 'running' || data === undefined ? (
                <Typography align="center">
                  <CircularProgress />
                </Typography>
              ) : (
                <Typography align="center" variant="h6" className={classes.checkResult}>
                  {data && data.results && data.results.length}
                </Typography>
              )}
            </Grid>
            <Grid item xs>
              <Typography align="center" variant="h5" className={classes.checkHeading}>
                % Match
              </Typography>
              <br />
              {data && data.similarityPercent === '' || data === undefined || data && data.status === 'running' ? (
                <Typography align="center">
                  <CircularProgress />
                </Typography>
              ) : (
                <Typography align="center" variant="h6" className={classes.checkResult}>
                  {data && data.similarityPercent === 'NaN' ? (
                    '0 %'
                  ) : (
                    <>{data && data.similarityPercent}</>
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item xs>
              <Typography align="center" variant="h5" className={classes.checkHeading}>
                Total Words
              </Typography>
              <br />
              {data && data.totalWords === 0 || data === undefined || data && data.status === 'running' ? (
                <Typography align="center">
                  <CircularProgress />
                </Typography>
              ) : (
                <Typography align="center" variant="h6" className={classes.checkResult}>
                  {data && data.totalWords}
                </Typography>
              )}
            </Grid>
            <Grid item xs>
              <Typography align="center" variant="h5" className={classes.checkHeading}>
                Report
              </Typography>
              <Typography align="center" variant="h6" className={classes.checkResult}>
                <ResultPdf userId={userInfo._id} scanId={scanId} />
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Divider className={classes.divider} />

        {/* Result List  */}
        <div className={classes.resultList}>
          <Paper className={classes.paper}>
            {result ? result.map((res, index) => (
              <>
                <ListItem alignItems="space-between" key={index}>
                  <ListItemText
                    className={classes.resultListBackground}
                    primary={
                      <>
                        <Typography variant="h6" className={classes.resultListHeading}>
                          {res.title}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <a
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                          href={res.url}
                          rel="noreferrer"
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ wordWrap: 'break-word' }}
                            className={classes.resultListLink}
                          >
                            {res.url}
                          </Typography>
                        </a>

                        <br />
                        <Typography
                          component="subtitle1"
                          variant="body2"
                          color="textSecondary"
                        >
                          {res.introduction}
                        </Typography>
                        <br />
                        <Typography
                          color="error"
                          component="h1"
                          variant="subtitle1"
                          className={classes.resultListPercent}
                          style={{ fontSize: '1.5vw' }}
                        >
                          {res.similarityPercent}%
                        </Typography>
                        <Typography
                          color="error"
                          component="h1"
                          variant="subtitle1"
                          className={classes.resultListPercent}
                          style={{ top: '4.4vw', fontSize: '0.95vw' }}
                        >
                          Similar Words
                        </Typography>

                        <br />
                        <Button onClick={() => redirectCompareText(res.url)}>
                          Compare Text
                        </Button>
                      </>
                    }
                  />
                </ListItem>
                <Divider
                  className={classes.divider}
                  style={{ width: '97%', borderBottom: '1.5px solid grey' }}
                />
                <br />
              </>
            )) :
              <Skeleton variant="rect" height={100} />
            }
            <br />

            {data && data.status === 'running' && (
              <>
                <Skeleton variant="rect" height={100} />
                <Divider
                  className={classes.divider}
                  style={{ width: '97%', borderBottom: '1.5px solid grey' }}
                />
                <br />
                <Skeleton variant="rect" height={100} />
                <Divider
                  className={classes.divider}
                  style={{ width: '97%', borderBottom: '1.5px solid grey' }}
                />
              </>
            )}
            {(data === undefined) && (
              <>
                <Skeleton variant="rect" height={100} />
                <Divider
                  className={classes.divider}
                  style={{ width: '97%', borderBottom: '1.5px solid grey' }}
                />
                <br />
                <Skeleton variant="rect" height={100} />
                <Divider
                  className={classes.divider}
                  style={{ width: '97%', borderBottom: '1.5px solid grey' }}
                />
              </>
            )}
          </Paper>
        </div>
      </Container>
    </>
  )
}

export default ScanResults;

