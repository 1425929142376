import React from 'react'
import { Link } from 'react-router-dom'
import {
  Typography
} from '@material-ui/core'


import { useStyles } from './contactRedirect.style'

import ArrowBtn from '../../../assets-new/Home/Arrow Btn.svg';

const ContactUsRedirect = () => {
    const classes = useStyles()
  return (
    <>
      <div className={classes.contactUs}>
          <p className={classes.contactUsText}>We would love <br/> to hear from you</p>
          
          <Link to="/Contact-us" style={{textDecorationColor: "#5145FF"}}>
          <Typography className={classes.contactUsHeading}>
            Contact Us
          </Typography>
          <img src={ArrowBtn} className={classes.contactUsArrow} />
          </Link>
      </div>
    </>
  )
}

export default ContactUsRedirect;