import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Getstarted from '../components/GetStarted/Getstarted'
import Footer from '../components/Footer'

function AboutUs() {
  return (
    <div>
      <Container>
        <Typography variant="h4">About Us</Typography>
        <br />
        <Typography variant="body1">
          IEMSECURE is a plagiarism-checking website provided by IEM America Corporation. The use
          of all services of IEMSECURE is subject to the terms and conditions of use.
          IEMSECURE uses search engines to check, under the terms agreed upon. The results you
          receive are generated by the post-processing of the search results that you receive.
          You can read the Frequently Asked Questions to know more about IEMSECURE.
          Please don’t hesitate to contact us at any time for any queries or suggestions.
        </Typography>
        <br />

        <Typography variant="h5">Check if you’re content is original</Typography>
        <br />
        <Typography variant="body1">
          You should be sure that the content you upload to your website should not be plagiarized.
          Plagiarized content can be harmful to your reputation and deteriorate your search engine ranking.
          Protect yourself from all these by checking your content for plagiarism on the IEMSECURE
          website.
        </Typography>
        <br />

        <Typography variant="h5">Check if anyone is copying your content</Typography>
        <br />
        <Typography variant="body1">
          Your content can be copied by anyone in this world. This can reduce the traffic to your website
          and should be not be allowed. By using IEMSECURE you can check for content that may have
          been copied from your website.
        </Typography>
        <br />

        <Typography variant="h5">IEMSECURE Services</Typography>
        <br />
        <Typography variant="body1">
          IEMSECURE provides the following services to help your content better-
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              IEMSECURE checks your content for plagiarism and lets you know if any of your
              content matches the content of any site.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              IEMSECURE also checks your content for grammar errors on the go.Along with
              plagiarism checking, you will also receive grammatical errors in the content and
              suggestions for the same.
            </Typography>
          </li>
        </ul>
        <Getstarted />
      </Container>

      <Footer />
    </div>
  )
}

export default AboutUs
