import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Getstarted from '../components/GetStarted/Getstarted'
import Footer from '../components/Footer'

function PrivacyPolicy() {
  return (
    <>
      <Container>
        <Typography variant="h4">Privacy Policy</Typography>
        <br />
        <Container>
          <Typography variant="h5">INTRODUCTION</Typography>
          <Typography variant="body2">
            We collect the minimum amount of the privacy statements that are required for
            us to fulfill your purposes. We do not send your information to third-party
            libraries and our use of the data is strictly limited to the description made
            by the Privacy Policy. We strongly intend to protect our customer’s privacy.
            We still reserve the right to change the privacy policy and will also update
            the same from time to time as necessary.
          </Typography>
          <br />
          <Typography variant="h5">Information may collect from the user-</Typography>
          <Typography variant="body2">
            We collect any information you submit to us:
          </Typography>
          <ul>
            <li>
              You have to register yourself as a user, we collect the information given by
              you in your request.
            </li>
            <li>
              We collect information when you contact us by email or telephone. We store
              the phone number or email address you use and also the information
              voluntarily given by you.
            </li>
            <li>
              We collect information when you leave any feedback. We collect information
              also when you request any support, any information that you enter in the
              request is stored.
            </li>
          </ul>
          <br />
          <Typography variant="h5">USE OF YOUR DATA</Typography>
          <Typography variant="body2">
            The data that is being collected is used only for IEMSecure. The personal
            information collected is used for the management of user accounts, completion
            of transactions, and send promotional emails. For fulfilling the requirements
            of the services, IEMSecure will use the information you provide.
          </Typography>
          <Typography variant="h5">PRIVACY OF DATA</Typography>
          <Typography variant="body2">
            We understand the fact that the data you send to IEMSecure is confidential and
            promise to abide by this fact. We never share any data unless it is required
            by law. The access to the data will be restricted to the team members of
            IEMSecure in order to provide support and service.
          </Typography>
        </Container>

        <Getstarted />
      </Container>

      <Footer />
    </>
  )
}

export default PrivacyPolicy
