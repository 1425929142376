import axios from 'axios'

const url = process.env.REACT_APP_API

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}


// Post Bug Bounty submit
export const bugBountySubmit = async (name, phoneNo,email,issueName,vulnerability,impact,steps) => {
    const body = {"name": name, "phoneNo": phoneNo,"email": email,"issueName": issueName,"vulnerability":vulnerability,"impact":impact,"steps":steps}
    console.log(body);
    const { data } = await axios.post(`${url}/contact/bug-bounty`, body, config)
    return data
}
