import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  getStarted: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    padding: '1rem 1.6rem 1rem 1.6rem !important',
    marginTop: '3.4rem !important',
    marginBottom: '3.4rem !important',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
  },

  actionBtn: {
    float: 'right',
    padding: '0.6rem 1rem 0.6rem 1rem',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}))
