import axios from 'axios'

const API = process.env.REACT_APP_API
const FAST_API = process.env.REACT_APP_FAST_API

// const API = "http://localhost:5000"
// const FAST_API = "http://localhost:8000"

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}

// upload file for scanning in FAST_API
export const uploadFile = async (fileUrl, userId) => {
  const body = {
    userId: userId,
    url: fileUrl,
  }

  const { data } = await axios.post(`${FAST_API}/report/file`, body, config)
  console.log(data)
  return data
}

// credit reduce for particular user 
export const creditReduce = async (wordCount, userId) => {
  const body = {
    wordsCount: wordCount,
    userId: userId
  }

  const { data } = await axios.post(`${API}/credit/reduce/${userId}`, body, config)
  console.log(data)
  return data
}


// Check credit in NODE
export const creditCheckForFile = async (fileUrl, userId) => {
  const body = {
    userId: userId,
    url: fileUrl,
  }

  console.log(fileUrl);

  const { data } = await axios.post(`${FAST_API}/file_word_count`, body, config)
  console.log(data)
  return data
}
