import { useContext, useState } from 'react'
import { FirebaseContext } from '../../../firebase/firebase'

const ResetPasswordLogic = (history) => {
  const { api } = useContext(FirebaseContext)

  const [email, setEmail] = useState('')

  const submitHandler = async (e) => {
    e.preventDefault()
    const errMsg = await api.resetPasswordEmail(email)
    console.log(errMsg)
    if (errMsg) {
      alert(errMsg)
    } else {
      history.push('/Login')
    }
  }
  return { submitHandler, setEmail, email }
}

export default ResetPasswordLogic
