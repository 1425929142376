import { Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import DashboardNav from '../../components/DashboardNav'
import { useStyles } from './style'

//Function which wrap its child components with normal navbar
export const DashboardNavRoute = ({ exact, path, component: Component }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <div className={classes.root}>
          {/* Dashboard Navbar */}
          <DashboardNav history={history} />
          <main className={classes.content}>
            <Component {...props} />
          </main>
        </div>
      )}
    />
  )
}
