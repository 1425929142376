import React from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
const Cookie = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Got it"
        cookieName="Cookie"
        hideOnAccept="true"
        style={{ background: '#6c63f3bd' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        <div>
          <Typography color="#fff" variant="subtitle1">
            {' '}
            By using our website you agree to our{' '}
            <Link to="/" style={{ color: '#fff' }}>
              {' '}
              cookie policy
            </Link>
          </Typography>
        </div>
      </CookieConsent>
    </>
  )
}

export default Cookie
