import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  whatWeDoSection: {
    marginTop: '5rem', 
    backgroundColor: "#FFF3F3", 
    padding: "3vw 0",
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem'
    }
  },
  sectionHeading: {
    width: "100%",
    textAlign: "center",
    fontFamily: "'Favorit', sans-serif",
    fontSize: "1.5vw", //2.5
    fontWeight: "500",
    color: "#333",
    [theme.breakpoints.down('sm')]: {
      fontSize: "5.2vw",
      marginTop: "9vw"
    }
  },
  sectionHeadingUnderline: {
    display: "block",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    width: "15.5vw",
    [theme.breakpoints.down('sm')]: {
      width: "55.5vw",
    }
  },
  easySectionContentGrid: {
    marginTop: "6vw",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0"
    }
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "ITC Avant Garde Gothic Std Md",
    fontSize: "1.1vw",
    color: "#000",
    position: "relative",
    width: "30vw",
    top: "0.8vw",
    left: "7vw",
    [theme.breakpoints.down('sm')]: {
      left: '8.2vw',
      fontSize: '4.45vw',
      width: "80vw",
    }
  },
  easyDescription: {
    textAlign: "right",
    left: "12.3vw",
    [theme.breakpoints.down('sm')]: {
      left: '10vw',
      marginBottom: "12vw"
    }
  },
  root: {
    width: '100%',
    marginTop: '2rem',
    marginBottom: '4rem',
  },
  leftImg: {
    width: '55%',
    marginLeft: "7vw",
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rightImg: {
    width: '67%',
    marginLeft: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '83.5%',
      position: 'relative',
      marginLeft: "0",
      marginTop: "12vw",
      left: '50%',
      transform: 'translateX(-50%)',
    }
  },
  heading: {
    position: 'relative',
    left: '6.7vw',
    textAlign: 'left',
    fontFamily: "'Favorit', sans-serif",
    fontSize: '3.6vw',
    fontWeight: 'semibold',
    color: '#000',
    width: "37vw",
    [theme.breakpoints.down('sm')]: {
      left: '8.2vw',
      fontSize: '8.45vw',
      width: "80vw",
    }
  },
  easyHeading: {
    left: "5.4vw",
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      left: '9.8vw',
    }
  },
  headingBorderText: {
    transform: "rotate(-3.05deg)",
    display: "inline-block",
    border: "solid 3.5px transparent",
    backgroundImage: "linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)",
    boxShadow: "2px 1000px 1px #FFF3F3 inset",
    borderRadius: "24px",
    padding: "0vw 1vw",
    lineHeight: "4.4vw",
    [theme.breakpoints.down('sm')]: {
      padding: "2vw 1.7vw",
    }
  },
  learnMoreLink: {
    color: "#5145FF",
    fontSize: "1.2vw",
    fontFamily: "ITC Avant Garde Gothic Std Md",
    marginLeft: "7vw",
    position: "relative",
    top: "1vw",
    [theme.breakpoints.down('sm')]: {
      marginLeft: '8.2vw',
      fontSize: '4.45vw',
    }
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepLabel: {
    fontSize: '1.6rem',
    color: 'rgb(81, 70, 175)',
    fontWeight: '800',
  },
}))
