import { Button, TextField, Paper, Container, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../auth.style'
import Footer from '../../../components/Footer'

import completeProfile from './profileComplete.js'

export default function ProfileComplete({ history }) {
  const classes = useStyles()
  const { submitHandler, orgName, setOrgName, contactNumber, setContactNumber } =
    completeProfile(history)

  return (
    <>

      <Container>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={false} sm={false} md={3}>
            {' '}
          </Grid>
          <Grid item xs={12} sm={12} md={6} component={Paper} className={classes.paperCompleteProfile}>
            <div className={classes.paper}>
              <Typography component="h1" variant="h4">
                Complete Your Profile
              </Typography>
              <br />
              <Typography component="h1" variant="h5"></Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="orgName"
                  label="Organisation Name"
                  name="orgName"
                  autoComplete="orgName"
                  autoFocus
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contactNumber"
                  label="Contact Number"
                  name="contactNumber"
                  autoComplete="contactNumber"
                  autoFocus
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={submitHandler}
                >
                  Proceed
                </Button>
              </form>
            </div>
          </Grid>
          <Grid item xs={false} sm={false} md={3}></Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  )
}
