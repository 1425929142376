import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  // Contact Us
  contactUs: {
    position: 'relative',
    width: '100%',
    marginTop: '3.5vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15.5vw',
      marginBottom: '13vw',
    },
  },
  contactUsText: {
    position: 'absolute',
    fontSize: '1.2vw',
    width: '12vw',
    top: '2vw',
    left: '5vw',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: '50%',
      top: '5vw',
      transform: 'translateX(-50%)',
      fontSize: '5vw',
      width: '80vw',
      textAlign: 'center',
    },
  },
  contactUsHeading: {
    position: 'relative',
    display: 'inline',
    width: '30vw',
    fontSize: '5vw',
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    fontWeight: '500',
    color: '#000',
    top: '0.5vw',
    left: '24vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12.7vw',
      display: 'block',
      width: '80vw',
      top: '3.5vw',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
    },
  },
  contactUsArrow: {
    position: 'absolute',
    display: 'inline',
    top: '2vw',
    left: '85vw',
    width: '5.5vw',
    transition: 'transform 100ms ease-in-out',
    '&:hover': {
      transform: 'rotate(-35deg)',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      top: '7vw',
      left: '50%',
      transform: 'translateX(-50%) rotate(-30deg)',
      width: '18.5vw',
    },
  },
}))

export { useStyles }
