import React from 'react'
import { Route, Link } from 'react-router-dom'

import {
  Button,
  Divider,
  Paper,
  Grid,
  Container,
  LinearProgress,
  Typography,
  Breadcrumbs,
} from '@material-ui/core'

import { Delete } from '@material-ui/icons'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { NewScanLogic } from './newScan'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from "react-loader-spinner";


//For text modal
import TextModal from '../../../components/Dashboard/TextModal/TextModal.jsx'

function NewScanDashboard({ history }) {
  const {
    classes,
    isDisabled,
    setIsDisabled,
    fileName,
    loadingScan,
    setLoadingScan,
    handleFile,
    deleteFile,
    scan,
    creditsDetails,
    uploadedFile,
    userInfo,
    isDisabledForCredit
  } = NewScanLogic(history)

  console.log(isDisabled)

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className={classes.breadcrumbs}
        aria-label="breadcrumb"
      >
        <Link to="/" style={{ textDecoration: 'none', color: '#5145FF' }}>
          <strong>IEMSECURE</strong>{' '}
        </Link>
        <Typography align="left" style={{fontWeight: "semibold", color: "#000"}}>
          New Scan
        </Typography>
      </Breadcrumbs>

      {/* Loader Animation when user scans any text or file */}
      {loadingScan ? (
        <div>
          <Container>
            <Alert variant="outlined" severity="info">
              We are working to produce report , We will mail you soon...
            </Alert>
          </Container>
          <LinearProgress />
        </div>
      ) : null}


      {/* Linear Loader when user give any file for scanning (untill file Credit Check done) */}
      {fileName ? uploadedFile ? null : <LinearProgress /> : null}


      {/* Upgrade Plan Paper */}
      {userInfo && userInfo.current_plan === 'Premium' ? ("") : (<Container>
        <Paper elevation={5} className={classes.creditPaper}>
          <Typography className={classes.textHeading} color="textprimary" align="left">
            Hey {userInfo && userInfo.name},
          </Typography>
          <Typography className={classes.textBody} color="textPrimary" align="left">
            Upgrade to {userInfo && userInfo.upgrade_plan} today!
          </Typography>
          <Link to="/dashboard/pricing">
            <Button className={classes.buyCredit} color="primary" variant="contained">
              Buy Credits
            </Button>
          </Link>
        </Paper>

        <Typography className={classes.currentPlan} color="primary" >
          Your current plan is {userInfo && userInfo.current_plan}
        </Typography>

      </Container>
      )
      }
      {isDisabled ? (
        <Container>
          <Alert style={{ marginTop: '1.5rem' }} severity="error">
            <AlertTitle>
              <strong>Recharge your account with some credit</strong>
            </AlertTitle>
          </Alert>
        </Container>
      ) : null}

      <Container maxWidth="md" className={isDisabled ? classes.disable : ''}>
        {/* Scan File */}
        {/*Local Files*/}
        <form encType="multipart/form-data" action="">
          <input
            id="fileUploadButton"
            name="fileUploadButton"
            type="file"
            onChange={(event) => handleFile(event)}
            hidden
            multiple="false"
            accept=".doc, .docx"
          />
        </form>

        <Paper elevation={6} className={classes.UploadedContainer}>
          {/*Scan Button-----> File Upload*/}
          <Paper elevation={0} className={classes.uploadPaper}>
            <label htmlFor={'fileUploadButton'}>
              <div style={{ cursor: 'pointer', marginTop:"1vw" }}>
                <Typography variant="subtitle1" align="center" className={classes.uploadPartIcon}>
                  <CloudUploadIcon color="primary" className={classes.icon} />
                  <br />
                  Click to upload file
                </Typography>
                <Typography variant="body2" align="center" className={classes.uploadPartIcon}>
                  (.doc, .docx) 
                </Typography>
                <Typography variant="body2" align="center" style={{marginTop: "0.5vw"}}>
                  One file at a time
                </Typography>
              </div>
            </label>
          </Paper>
          <Divider style={{border: "none",borderBottom:"1.8px solid #555"}} className={classes.divider}/>
          {/*Scan Button-----> Text Upload*/}
          <div className={classes.textpaper}>
            <Route
              render={({ history }) => (
                <TextModal
                  isDisabled={isDisabled}
                  className={isDisabled ? classes.disable : ''}
                  history={history}
                  setLoadingScan={setLoadingScan}
                  setIsDisabled={setIsDisabled}
                />
              )}
            />
          </div>
        </Paper>
      </Container>

      {/*Uploaded Files*/}
      <Container
        aria-disabled={isDisabled}
        className={isDisabled ? classes.disable : ' '}
      >
        <Typography
          variant="h5"
          color="primary"
          align="center"
          style={{ fontWeight: '600' }}
        >
          Your Uploads
        </Typography>
        <br />
        <Paper className={classes.UploadedDocContainer}>
          <Divider className={classes.divider}/>
          {fileName ? (
            /*Showing Uploaded Document*/
            <Paper className={classes.UploadedDoc}>
              <Grid container>
                <Grid item xs={7}>
                  <Typography variant="h6" align="left">
                    {fileName}
                  </Typography>
                </Grid>
                {creditsDetails && creditsDetails.wordsCount ?
                  <Grid item xs={4}>
                    <Typography className={classes.wordsCount} variant="h6" align="right">
                      {creditsDetails && creditsDetails.wordsCount} words
                    </Typography>
                  </Grid>
                  :
                  (
                    <Grid item xs={4}>
                      <div className={classes.calculatingWords}>
                        <Loader type="ThreeDots" color="#5045FF" height={30} width={60} />
                        <div>Calculating your words</div>
                      </div>
                    </Grid>
                  )
                }
                <Grid item xs={1}>
                  <div style={{ cursor: 'pointer', marginTop: "0.2vw" }}>
                    <Typography variant="h6" align="right" color="primary">
                      <Delete onClick={deleteFile} />
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <div>
              <br />
              <Typography variant="h5" align="center">
                No File Chosen Yet
              </Typography>
              <br />
            </div>
          )}
          <Divider className={classes.divider}/>
          <br />
          <Typography align="center">
            <Button
              disabled={
                fileName ? (uploadedFile && !isDisabledForCredit ? false : true) : (isDisabled ? true : false)
              }
              className={classes.fileUploadScanBtn}
              size="large"
              onClick={scan}
            >
              Scan Now
            </Button>
          </Typography>

          {fileName ? (
            uploadedFile ? null : (
              <Typography
                variant="body2"
                color="primary"
                align="center"
              >
                Uploading your file...
              </Typography>
            )
          ) : null}
        </Paper>
      </Container>
    </div>
  )
}

export default NewScanDashboard
