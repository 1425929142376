import { useState, useContext } from 'react'

import { useStyles } from '../auth.style'

import { useSelector } from 'react-redux'

import { FirebaseContext } from '../../../firebase/firebase'
import { useMediaQuery, useTheme } from '@material-ui/core'

const RegisterLogic = (history) => {
  const classes = useStyles()

  //For media querries
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))

  // get api for register
  const { api } = useContext(FirebaseContext)

  //get state from reducer for loader
  const { loading } = useSelector((state) => state.userAdd)

  const [showPassword, setShowPassword] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [number, setNumber] = useState('')
  const [OrgName, setOrgName] = useState('')
  const [error, setError] = useState('')

  // Handling Register Submit
  const RegisterSubmitHandler = async (e) => {
    e.preventDefault()
    // api for login
    if (password === confirmPassword) {
      const errMsg = await api.signupUser(email, password, name, number, OrgName)
      if (errMsg) {
        setError(errMsg)
      } else {
        history.push('/login')
      }
    } else {
      setError('Password does not match')
    }
  }

  return {
    classes,
    RegisterSubmitHandler,
    email,
    setEmail,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    loading,
    OrgName,
    setOrgName,
    number,
    setNumber,
    name,
    setName,
    confirmPassword,
    setConfirmPassword,
    isMobile,
    error,
  }
}

export default RegisterLogic
