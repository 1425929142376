import { makeStyles } from '@material-ui/core/styles'

const sideNavWidth = 210

export const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    // margin: '1rem 1rem 1rem 0'
  },
  navLogo: {
    position: 'relative',
    width: "7vw",
    left: "5.8vw",
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      width: "28vw",
      left: "4vw",
      top: "0.5vw"
    }
  },
  allNavItems: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    left: "44.5vw",
    width: "40vw"
  },
  navItems: {
    color: "#000",
    flexGrow: 1,
    fontWeight: 600,
    fontSize: '1.08rem',
    fontFamily: 'Montserrat',
    margin: '0.3rem 2.67rem 0',
    '&:hover': {
      color: '#5145FF'
    },
  },
  navItems_Register: {
    color: "#000",
    flexGrow: 1,
    fontWeight: 600,
    fontSize: '1.08rem',
    fontFamily: 'Montserrat',
    margin: '0 2.67rem',
    borderRadius: "100rem",
    padding: "0.11rem 2.4rem",
    border: "solid 2.5px transparent",
    backgroundImage: "linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)",
    boxShadow: "2px 1000px 1px #fff inset",
    '&:hover': {
      color: '#fff',
      boxShadow: "none"
    }
  },
  menuButton: {
    // marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
    marginLeft: "50%"
  },
  menuButtonHam: {
    width: "10vw",
    height: "10vw"
  },
  list: {
    width: sideNavWidth,
  },

  mobilenavItems: {
    color: '#4D7178',
    fontWeight: 600,
    margin: theme.spacing(3, 0),
  },
}))
