import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  first: {
    position: 'absolute',
    width: '33.33%',
    height: '100%',
    top: '0%',
    left: '0%',
    zIndex: '99',
    background: '#fff',
    animation: '$loaderAnimation 0.6s ease-in-out',
    animationDelay: '1s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  },
  second: {
    position: 'absolute',
    width: '33.33%',
    height: '100%',
    top: '0%',
    left: '33.33%',
    zIndex: '98',
    background: '#fff',
    animation: '$loaderAnimation 0.6s ease-in-out',
    animationDelay: '1.2s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  },
  third: {
    position: 'absolute',
    width: '33.33%',
    height: '100%',
    top: '0%',
    left: '66.66%',
    zIndex: '98',
    background: '#fff',
    animation: '$loaderAnimation 0.6s ease-in-out',
    animationDelay: '1.4s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  },

  '@keyframes loaderAnimation': {
    from: { height: '100%' },
    to: { height: '0%' },
  },
  fourth: {
    overflowX: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0%',
    left: '0%',
    zIndex: '96',
    background: '#5345FF',
    animation: '$fourthAnimation 0.8s ease-in-out',
    animationDelay: '2s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  },
  '@keyframes fourthAnimation': {
    from: {
      width: '100%',
    },
    to: {
      width: '0%',
    },
  },

  choosePlanHeading: {
    marginTop: '3vw',
    fontWeight: '800',
    position: 'absolute',
    textTransform: 'none',
    color: '#fff',
    fontSize: '2.8vw',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      fontSize: '7.8vw',
      marginTop: '0',
      top: '10vw',
    },
  },
}))
