import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export const makePaymentHelper = async (token, userInfo, creditId) => {
  const body = {
    token: token,
    userId: userInfo._id,
    creditId: creditId,
  }

  const { data } = await axios.post(`${API}/payment`, body, config)

  // change localStorage or sessionStorage in userInfo (for upgrade_plan)
  if (localStorage.getItem('userInfo')) {
    localStorage.setItem('userInfo', JSON.stringify(data.user))
  } else if (sessionStorage.getItem('userInfo')) {
    sessionStorage.setItem('userInfo', JSON.stringify(data.user))
  }

  console.log(data)
  return data
}
