import React from 'react'
import ReactDOM from 'react-dom'
import FirebaseProvider from './firebase/firebase'

import { Provider } from 'react-redux'
import store from './redux/store'

import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider>
      <App />
    </FirebaseProvider>
  </Provider>,

  document.getElementById('root')
)
