import React from 'react'
import Container from '@material-ui/core/Container'
import Getstarted from '../components/GetStarted/Getstarted'
import Footer from '../components/Footer'

function TandC() {
  return (
    <div>
      <Container maxWidth="md">
        <h1>Terms and conditions</h1>
        <p>
          By using IEMSecure, you agree to the terms and conditions in this agreement.
          Please read the terms below. These terms of the License apply to all versions of
          IEMSECURE. If you do not agree to the terms of IEMSECURE, please do not use
          IEMSECURE.
        </p>
        <ol>
          <li>
            <b>Scope of Licence</b>
            <p>
              Users have to create a single user account to use the services offered by
              IEMSECURE. Unregistered use of IEMSECURE is not available as it is
              prohibited by violation of India and international copyright laws.
            </p>
            <p>
              You are not allowed to alter or do any kind of modification to the software,
              nor give any permission to do so. All rights regarding IEMSECURE that are
              not mentioned in this license are reserved by IEMSECURE. You are not allowed
              to modify, lease, modify, decompile, disassemble or create any derivative
              work based on IEMSECURE.
            </p>
          </li>
          <li>
            <b>Credits and Payments: </b>

            <p>
              Credits can be purchased at the rate shown on our pricing page. These
              credits can be used to perform the scans in IEMSECURE.
            </p>
            <p>
              By using one credit, you can scan a document that contains up to 250 words.
              For documents containing more than 250 words, you will have to use more than
              one credit, depending on the word count of the document.
            </p>
            <p>
              IEMSECURE reserves the right to revise their cost of purchase of credits or
              the word limit at any time. The changes will be based on the cost of
              operation and will be upgraded ensuring that the existing credits have the
              same value in terms of the word limit of scans.
            </p>

            <b>Storage and Use of scanned document-</b>

            <p>
              Your scanned documents will not be used for any other purposes other than
              those which are explained in this License.
            </p>
            <p>
              When you scan a document, IEMSECURE will retain has the license to store the
              document for the comparison function within the same software.
            </p>
            <p>
              IEMSECURE does not reserve any right to redistribute, share or publish any
              document scanned by the user. If any document matches with the stored
              document, the user will only see the percentage of matching content only,
              the user will never be able to see the document itself, the document will be
              kept confidential.
            </p>
          </li>         
        </ol>
        <br /> <br />
        <Getstarted />
      </Container>
      <Footer />
    </div>
  )
}

export default TandC
