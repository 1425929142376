import React from 'react'
import { Link } from 'react-router-dom'

import {
  Card,
  CardActions,
  Breadcrumbs,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Typography,
  Button,
} from '@material-ui/core'

import StarIcon from '@material-ui/icons/StarBorder'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { useStyles } from './pricing.style'
import { tiers } from './PlansTier'

import StripeCheckout from 'react-stripe-checkout'
import { PricingLogic } from './pricing'

const PricingPlans = () => {
  const classes = useStyles()
  const { makePayment } = PricingLogic()

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className={classes.breadcrumbs}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
          <strong>IEMSECURE</strong>{' '}
        </Link>
        <Typography color="textPrimary">Pricing</Typography>
      </Breadcrumbs>

      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
      </Container>
      {/* End hero unit */}
      <Container component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 10 : 6}
              md={4}
            >
              <Card elevation={10}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subHeader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {tier.title === 'For Custom Plans' ? '' : `$ ${tier.price}`}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {tier.title === 'For Custom Plans' ? '' : ` /mo`}
                    </Typography>
                  </div>
                  <Container>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                        >
                          ✓ {line}
                        </Typography>
                      ))}
                    </ul>
                  </Container>
                </CardContent>
                <CardActions>
                  {tier.title === 'For Custom Plans' ? (
                   ''
                  ) : (
                    <>
                      <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                        token={(token) => makePayment(token, tier.creditId)}
                        // amount={totalPrice * 100}
                        currency="INR"
                        name="Pay Now"
                        shippingAddress
                      >
                        <Button
                          className={classes.cardButton}
                          variant={tier.buttonVariant}
                          color="primary"
                        >
                          {tier.buttonText}
                        </Button>
                      </StripeCheckout>
                    </>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
        <div className={classes.personalPlanCard}>
          <Typography className={classes.personalPlanHeading}>
          Want a custom plan made only for you? ✨
          </Typography>
          <a href="mailto:support@iemsecure.com" target="_blank" className={classes.personalPlanButton} style={{ textDecoration: 'none'}}>Contact Sales</a>
        </div>
      </Container>
   </>
  )
}

export default PricingPlans
