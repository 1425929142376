import axios from 'axios'

const API = process.env.REACT_APP_API
const url = `${API}` // file

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}


export const enableTwoFactorAuth = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${url}/users/enable-twoFA`, body, config)

    // change localStorage or sessionStorage in userInfo (for twoFactorEnabled)
    if (localStorage.getItem('userInfo')) {
        localStorage.setItem('userInfo', JSON.stringify(data.user))
    } else if (sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo', JSON.stringify(data.user))
    }

    console.log(data)
    return data
}

export const enableTwoFactorSmsAuth = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${url}/users/enable-twoFA-sms`, body, config)

    // change localStorage or sessionStorage in userInfo (for twoFactorEnabled)
    if (localStorage.getItem('userInfo')) {
        localStorage.setItem('userInfo', JSON.stringify(data.user))
    } else if (sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo', JSON.stringify(data.user))
    }

    console.log(data)
    return data
}


// enable 2FA for Authenticator
export const enableTwoFactorAuthenticator = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${url}/users/enable-twoFA-authenticator`, body, config)

    // change localStorage or sessionStorage in userInfo (for twoFactorEnabled)
    if (localStorage.getItem('userInfo')) {
        localStorage.setItem('userInfo', JSON.stringify(data.user))
    } else if (sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo', JSON.stringify(data.user))
    }

    console.log(data)
    return data
}


export const addNewsletterSubscription = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${url}/newsletter/subscribe`, body, config)

    // change localStorage or sessionStorage in userInfo (for twoFactorEnabled)
    if (localStorage.getItem('userInfo')) {
        localStorage.setItem('userInfo', JSON.stringify(data.user))
    } else if (sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo', JSON.stringify(data.user))
    }
    return data
}


export const removeNewsletterSubscription = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${url}/newsletter/unsubscribe`, body, config)

    // change localStorage or sessionStorage in userInfo (for twoFactorEnabled)
    if (localStorage.getItem('userInfo')) {
        localStorage.setItem('userInfo', JSON.stringify(data.user))
    } else if (sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo', JSON.stringify(data.user))
    }
    return data
}


// upload photo 
export const uploadPhoto = async (photo_url, userId) => {
    const body = {
        photo_url: photo_url
    }

    const { data } = await axios.post(`${url}/users-profile/upload/photo/${userId}`, body, config)

    console.log(data)
    return data
}


// purchase history 
export const purchaseHistoryHelper = async (userId) => {
    const body = {
        userId: userId
    }

    const { data } = await axios.post(`${url}/payment/purchase-history`, body, config)

    console.log(data)
    return data
}


export const cancelPlanSubscription = async (email, name, contact, plan) => {
    const body = {
        email: email,
        name: name,
        phn_no: contact,
        current_plan: plan
    }
    const { data } = await axios.post(`${url}/users-profile/cancelSubscription`, body, config)
    return data
}