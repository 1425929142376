import { Link } from 'react-router-dom'

import { Button, TextField, Paper, Container, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../auth.style'
import Footer from '../../../components/Footer'

import ResetPasswordLogic from './resetPasswordLogic'

export default function LoginScreen({ history }) {
  const classes = useStyles()
  const { submitHandler, setEmail, email } = ResetPasswordLogic(history)
  return (
    <>
      <Container>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={false} sm={false} md={3}>
            {' '}
          </Grid>
          <Grid item xs={12} sm={12} md={6} component={Paper} className={classes.paper}>
            <div className={classes.paper}>
              <Typography component="h1" variant="h4">
                Reset Password
              </Typography>
              <br />
              <Typography component="h1" variant="h5"></Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={submitHandler}
                >
                  Send Mail
                </Button>
                <Grid container>
                  <Grid item xs={12}>
                    <Link to="/Login" variant="body2">
                      {'Already Remembered Your Password'}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item xs={false} sm={false} md={3}></Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  )
}
