import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: '1rem 0 0 1rem',
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  UploadedContainer: {
    margin: '3rem auto',
    width: '62%',
    borderRadius: "17px"
  },
  uploadPaper: {
    padding: '1.5rem 0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer !important',
    borderRadius: "17px"
  },

  creditPaper: {
    backgroundColor: '#fff',
    padding: '1.2rem 2.4rem',
    width: '90%',
    margin: '1rem auto',
    alignItems: 'left',
  },

  textHeading: {
    color: '#5045FF',
    fontSize: '1.25rem',
    marginBottom: '0.2rem',
  },

  textBody: {
    color: '#5045FF',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },

  buyCredit: {
    float: 'right',
    marginTop: '-3.6rem',
    padding: '0.8rem',
  },

  currentPlan: {
    paddingLeft: "3.8rem"
  },

  calculatingWords: {
    display: "flex",
    justifyContent: "space-around"
  },

  UploadedDocContainer: {
    padding: '0.5rem',
    backgroundColor: '#fff',
    width: '90%',
    margin: '0 auto 1rem',
    padding: "3vw 0 2vw",
    alignItems: 'center',
    justifyContent: 'center',
  },
  UploadedDoc: {
    padding: '1rem 2.4rem',
    margin: '1rem auto',
    width: "90%",
    backgroundColor: "#F0EEFF"
  },
  textpaper: {
    padding: '1.1rem 1.5rem 1.5rem',
  },
  uploadPartIcon: {
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat',sans-serif",
    color: "#5e5e5e",
    fontSize: '1.2vw',
    lineHeight: '1.6vw'
  },
  divider: {
    border: '0.6px solid #888',
    width: "90%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)"
  },
  fileUploadScanBtn: {
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    fontSize: "1.2vw",
    color: "#fff",
    backgroundColor: '#FF4B4B',
    padding: "0.5vw 1.5vw",
    borderRadius: "10px",
    textTransform: "none",
    marginTop: "0.5vw",
    "&:hover": {
      backgroundColor: "#DD1616"
    }
  },
  icon: {
    fontSize: '4.8rem',
    // animationName: '$blinker',
    // animationDuration: '3s',
    // animationTimingFunction: 'alternate',
    // animationIterationCount: 'infinite',
  },

  '@keyframes blinker': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-10px)' },
    '100%': { transform: 'translateY(0px)' },
  },
}))
