import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { NavbarLogic } from './navbar'

//assets
import NavLogo from "../../assets-new/NavLogo.svg";

function Navbar() {
  const { classes, toggle, setToggle, userInfo, isMobile } = NavbarLogic()

  return (
    <>
      <Toolbar style={{ backgroundColor: "none" }}>
        {/*Nav Header*/}
        <Link to="/" >
          <img src={NavLogo} className={classes.navLogo} />
        </Link>
        {isMobile ? (
          <div className={classes.allNavItems}>
            {/*---------------Desktop View Nav items--------------------------*/}
            <Link to="/pricing" style={{ textDecoration: 'none' }}>
              <Typography variant="body2" className={classes.navItems}>
                Pricing
              </Typography>
            </Link>

            <Link to="/Contact-us" style={{ textDecoration: 'none' }}>
              <Typography variant="body2" className={classes.navItems}>
                Contact
              </Typography>
            </Link>

            {userInfo ? (
              <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" className={classes.navItems}>
                  Dashboard
                </Typography>
              </Link>
            ) : (
              <>
                <Link
                  to={userInfo ? '/dashboard' : '/login'}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography variant="body2" className={classes.navItems}>
                    Login
                  </Typography>
                </Link>

                <Link to={'/register'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.navItems_Register}>Register</Typography>
                </Link>
              </>
            )}

            {/*---------------End of  Desktop View Nav items--------------------------*/}
          </div>
        ) : (
          <>
            {/*Mobile view navbar*/}
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={() => setToggle(true)}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon className={classes.menuButtonHam} />
            </IconButton>

            {/*--------------------------------------FOR MOBILE VIEW---------------------------*/}
            <Drawer anchor="right" open={toggle} onClose={() => setToggle(false)}>
              <List className={classes.list}>
                <Link to="/pricing" style={{ textDecoration: 'none' }}>
                  <ListItem button divider>
                    <ListItemText
                      inset
                      primary="Pricing"
                      className={classes.mobilenavItems}
                      onClick={() => setToggle(false)}
                    />
                  </ListItem>
                </Link>

                <Link to="/contact-us" style={{ textDecoration: 'none' }}>
                  <ListItem button divider>
                    <ListItemText
                      inset
                      primary="Contact"
                      className={classes.mobilenavItems}
                      onClick={() => setToggle(false)}
                    />
                  </ListItem>
                </Link>

                {userInfo ? (
                  <Link
                    to={userInfo ? '/dashboard' : '/login'}
                    style={{ textDecoration: 'none' }}
                  >
                    <ListItem button divider>
                      <ListItemText
                        inset
                        primary="Dashboard"
                        className={classes.mobilenavItems}
                        onClick={() => setToggle(false)}
                      />
                    </ListItem>
                  </Link>
                ) : (
                  <>
                    <Link
                      to={userInfo ? '/dashboard' : '/Login'}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem button divider>
                        <ListItemText
                          inset
                          primary="Login"
                          className={classes.mobilenavItems}
                          onClick={() => setToggle(false)}
                        />
                      </ListItem>
                    </Link>

                    <Link
                      to={userInfo ? '/dashboard' : '/register'}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem button divider>
                        <ListItemText
                          inset
                          primary="Register"
                          className={classes.mobilenavItems}
                          onClick={() => setToggle(false)}
                        />
                      </ListItem>
                    </Link>
                  </>
                )}
              </List>
            </Drawer>
            {/*---------------------------------FOR MOBILE VIEW-----------------------------------*/}
          </>
        )}
      </Toolbar>
    </>
  )
}

export default Navbar
