import React from 'react'
import PricingCards from '../../components/PricingCards'
import PlansFAQ from '../../components/PricingFaq/PlansFAQ'
import { Container, Typography } from '@material-ui/core'
import Getstarted from '../../components/GetStarted/Getstarted'
import Footer from '../../components/Footer'

function Pricing() {
  return (
    <>
      <div style={{overflow: 'hidden'}}>
        {/**Pricing heading */}
        <Typography
          component="h1"
          variant="h2"
          align="center"
          style={{ marginTop: "6vw",fontWeight: 800, position: 'absolute', textTransform: "none", color: "#fff", fontSize: "2.8vw", left: "50%", transform: "translateX(-50%)"}}
        >
         Choose your plan
        </Typography>
        {/**Pricing Description */}
        <Typography></Typography>
        <PricingCards />
        <br />
        <br />
        <Container>
          <PlansFAQ />
          <Getstarted />
        </Container>
      </div>

      <Footer />
    </>
  )
}

export default Pricing
