import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reportList } from '../../../redux/actions/reportAction.js'
import { userProfile } from '../../../redux/actions/userProfile'

export const ScanListLogic = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { userInfo } = useSelector((state) => state.userSignin)

  const API = process.env.REACT_APP_API

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(reportList(userInfo._id, API))
    }
  }, [dispatch, userInfo, history, API])

  useEffect(() => {
    dispatch(userProfile(userInfo._id))
  }, [history, userInfo, dispatch])

  const { data, loading } = useSelector((state) => state.reportList)

  return {
    data,
    loading,
    userInfo,
  }
}
