import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useStyles } from './Navbar.style'
import { useMediaQuery } from '@material-ui/core'

import { useSelector } from 'react-redux'

export const NavbarLogic = () => {
  const classes = useStyles()
  const [toggle, setToggle] = useState(false)

  // redirect to dashboard page if logged in
  const { userInfo } = useSelector((state) => state.userSignin)

  //For media querries
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))

  return { classes, toggle, setToggle, userInfo, isMobile }
}
