import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontFamily: "'Favorit','Montserrat', sans-serif",
    color: '#000',
    fontSize: "2vw",
    fontWeight: '600',
    textTransform: "none",
    marginTop: "0.8vw"
  },
  checkHeading: {
    fontFamily: "'ITC Avant Garde Gothic Std Md','Montserrat', sans-serif",
    color: '#1E0970',
    fontSize: "1.15vw",
    lineHeight: "1.7vw"
  },
  checkResult: {
    fontFamily: "'Montserrat', sans-serif",
    color: '#252525',
    fontSize: "1.1vw",
    fontWeight: '500',
    lineHeight: "1.7vw"
  },
  UploadedDocContainer: {
    padding: '1.8rem',
    backgroundColor: '#f0f0f0',
    width: '65vw',
    margin: '0 auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 330,
    display: 'flex',
  },
  UploadedDoc: {
    padding: '1rem 2.4rem',
    margin: '1rem 0.5rem',
  },

  root: {
    display: 'flex',
    minHeight: '100vh',
  },

  label: {
    padding: '3rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  uploadPaper: {
    width: '50vw',
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadFileText: {
    fontSize: '1.2rem',
  },
  subUploadText: {
    fontSize: '0.8rem',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#c2bffd4a',
    marginTop: '-0.6rem',
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
  },
  paper: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
    width: "91%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)"
  },
  '@media screen and (max-width: 800px)': {
    paper: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      width: '90vw'
    },
  },
  divider: {
    width: "91%",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    border: "none",
    borderBottom: "2px solid #777"
  },
  fixedHeight: {
    height: 240,
  },
  Paper: {
    padding: '2rem',
    height: '23rem',
    marginTop: '1rem',
    marginBottom: '4rem',
    width: '100%',
  },
  PaperMobile: {
    padding: '2rem',
    height: '24rem',
    marginTop: '4rem',
    marginBottom: '2.1rem',
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.2rem',
    fontWeight: 900,
    color: '#4c42fa',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  rightHeading: {
    marginTop: '4rem',
    fontSize: '2.6rem',
    fontWeight: 900,
    color: '#4c42fa',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  rightPara: {
    //  marginLeft: "3.9rem",
    marginTop: '1rem',
    fontSize: '1.16rem',
  },
  form: {
    marginTop: '1rem',
    width: '100%',
    border: '1px solid #9999f1b0',
    borderRadius: '6px',
  },
  submit: {
    backgroundColor: '#008a00',
    color: '#fff',
    marginTop: '0.6rem',
    borderRadius: '6px',
    padding: '0.4rem 2rem 0.4rem 2rem',
    fontSize: '1.04rem',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    width: '50%',
    '&:hover': {
      backgroundColor: '#008a00',
    },
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  resultListBackground: {
    background: '#fff1f1',
    padding: '0.6rem',
    margin: "0vw auto 1vw"
  },
  resultList: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%"
  },
  resultListHeading: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1.2vw",
    fontWeight: "500",
    color: "#000",
    width: "75%",
    display: "inline"

  },
  resultListLink: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1vw",
    fontStyle: "italic",
    marginTop: "0.4vw",
    width: "75%"
  },
  resultListPercent: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1.1vw",
    marginTop: "0.4vw",
    position: "absolute",
    left: "83%",
    textTransform: "none",
    top: "20%",
    width: "7vw",
    textAlign: "center",
  }
}))
