import React from 'react'
import Iframe from 'react-iframe'
import { useSelector } from "react-redux"

function CompareText() {

    // selector
    const { data } = useSelector((state) => state.resultUrl)
    console.log(data);

    return (
        <div>
            <Iframe url={data}
                width="100%"
                height="1050px"
                style={{ height: "90vh" }}
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                loading='lazy'
            />
        </div>
    )
}

export default CompareText
