import axios from 'axios';
import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILED,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_FAILED,
  USER_LOGOUT,
} from '../constants';

///    ADD USER    ///
export const addUser = (idToken, name, number, orgname, API) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SIGNUP_REQUEST,
    });

    const config = {
      'Content-Type': 'application/json',
    };

    const body = {
      idToken: idToken,
      name: name,
      phn: number,
      orgname: orgname,
    };

    const { data } = await axios.post(`${API}/users/add-user`, body, config);

    console.log(data);
    dispatch({
      type: USER_SIGNUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SignUp social auth user
export const signUpSocialUser = (idToken, API) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      idToken: idToken,
    };

    const { data } = await axios.post(
      `${API}/users/sign-up-social-user`,
      body,
      config
    );
    console.log(data);
    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    // save auth details to sessionStorage
    sessionStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


// SignIn social auth user
export const signInSocialUser = (idToken, API) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      idToken: idToken,
    };

    const { data } = await axios.post(
      `${API}/users/sign-in-social-user`,
      body,
      config
    );
    console.log(data);
    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    // save auth details to sessionStorage
    sessionStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// signIn user
export const signInUser = (user, API, rememberMe) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });

    const config = {
      'Content-Type': 'application/json',
    };
    const { data } = await axios.post(
      `${API}/users/user-profile`,
      user,
      config
    );
    console.log(data);
    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    if (rememberMe) {
      localStorage.setItem('userInfo', JSON.stringify(data));
    } else {
      sessionStorage.setItem('userInfo', JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// user logout
export const logoutUser = () => async (dispatch) => {
  if (localStorage.getItem("userInfo")) {
    localStorage.removeItem('userInfo');
  } else if (sessionStorage.getItem('userInfo')) {
    sessionStorage.removeItem("userInfo");
  }
  dispatch({
    type: USER_LOGOUT,
  });
};