import { Container, Typography, TextField, Button, Paper, Grid } from '@material-ui/core'
import { Card, CardContent,} from '@material-ui/core'
import { BugBountyLogic } from './bugBounty'
import { tiers } from './BugBountyTier'
import { useStyles } from './bugBounty.style'
function BugBounty() {
  const classes = useStyles()
  const {
    name,
    setName,
    phoneNo,
    setPhoneNo,
    email,
    setEmail,
    issueName,
    setIssueName,
    vulnerability,
    setVulnerability,
    impact,
    setImpact,
    steps,
    setSteps,
    submitHandeller,
  } = BugBountyLogic()
  return (
  <>
    <div className={classes.cover}>
      <div className={classes.margin}>.</div>
      <Container maxWidth='lg'>
      <Paper className={classes.Paper}>
          <Typography variant='h4' align='center'>Hunt For Bugs In Our System And Get Awarded With Hall Of Fame’s And Exciting Rewards</Typography>
          <br/><br/>
          <Grid container>
            <Grid item md={6} xs={12}><br/> <img src="https://i0.wp.com/iemlabs.com/wp-content/uploads/2021/06/bug-bounty-program.png?fit=640%2C349&ssl=1" style={{width: '90%'}}/> </Grid>
            <Grid item md={6} xs={12}> 
              <Typography variant='h6'>About The Program</Typography> <br/>
              <Typography>Bug Bounty Program by IEMSecure is an initiative to encourage young talents in the field on Cyber Security to find out and report critical vulnerabilities. We invite all Ethical Hackers and Cyber Security Professionals to participate in our Bug Bounty Program and raise the standard of the Cyber Security industry. A researcher who successfully finds and report vulnerability will be awarded with “Hall of Fame Certificate” and “Exciting Goodies” from IEMSecure.</Typography>
            </Grid>
          </Grid>
          <div className={classes.margin}>.</div>
          <Typography variant='h4' align='center'>Scope Areas</Typography>
          <Container>
            
          <div className={classes.margin}>.</div>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              md={6}
              xs={12} 
            >
              <Card elevation={7} className={classes.card}>
                <div className={classes.cardPricing}>
                  <Typography className={classes.cardCredit} style={{textAlign: "center"}}>
                      {tier.credits}
                  </Typography>
                  </div>
                  <br/><br/><br/>
                <CardContent>
                  <Container>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={line}
                          className={classes.cardPlanFeatures}
                        >
                          ✓ {line}
                        </Typography>
                      ))}
                    </ul>
                  </Container>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div className={classes.margin}>.</div>
        </Container>
      </Paper>
      </Container>
      <Container maxWidth="md">
        <Paper className={classes.Paper}>
        <Typography align="center" variant="h4">
          Submission Form
        </Typography>

        <TextField
          label="Contact Name"
          value={name}
          fullWidth
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Phone number"
          fullWidth
          type="number"
          value={phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Email"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Issue Name"
          fullWidth
          value={issueName}
          onChange={(e) => setIssueName(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Description of Vulnerability"
          fullWidth
          multiline
          rows={18}
          rowsMax={3}
          value={vulnerability}
          onChange={(e) => setVulnerability(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Impact"
          fullWidth
          multiline
          rows={18}
          rowsMax={3}
          value={impact}
          onChange={(e) => setImpact(e.target.value)}
        />
        <br />
        <br />
        <TextField
          label="Steps to Reproduce"
          fullWidth
          multiline
          rows={18}
          rowsMax={3}
          value={steps}
          onChange={(e) => setSteps(e.target.value)}
        />
        <br />
        <br />
        <Button variant="outlined" onClick={submitHandeller}>
          Send
        </Button>
        <br/><br/>
        </Paper>
        <div className={classes.margin}>.</div>      <div className={classes.margin}>.</div>
      </Container>
    </div>
  </>
  )
}

export default BugBounty
