import { makeStyles } from '@material-ui/core/styles'
import BgDashboard from '../../assets-new/dashboardBg.png';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '120vh',
  },

  content: {
    flexGrow: 1,
    background: `url(${BgDashboard})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: '4rem',
  },
}))
