import { makeStyles } from '@material-ui/core/styles'
import BgPricing from '../../assets-new/Home/Purple Wave Bg Card.png'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(3, 0, 6),
  },

  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardGrids: {
    marginTop: "9.7vw",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0vw",
      position: 'relative',  
      left: "50%",
      top: "15vw",
      transform: "translateX(-50%)",
    }
  },
  cardHeader: {
    color: '#fff',
    fontWeight: '900',
    fontSize: '1vw',
    backgroundSize: 'cover',
  },
  card: {
    position: 'relative', 
    width: "20vw", 
    height: "29vw", 
    left: "50%", 
    transform: "translateX(-50%)",
    [theme.breakpoints.down('sm')]: {
      width: "85vw", 
      height: "118vw",
    }
  },
  cardCredit: {
    position: 'relative',
    display: 'block',
    width: "100%",
    color: "#5145FF",
    fontSize: "1.2vw",
    fontWeight: "bold",
    fontFamily: "'Favorit', sans-serif",
    marginTop: "1.1vw",
    [theme.breakpoints.down('sm')]: {
      fontSize: "5.3vw",
      marginTop: "3vw",
    }
  },

  cardPricing: {
    position: 'absolute',
    display: 'block',
    top: "0",
    left: "50%",
    transform: 'translateX(-50%)',
    fontSize: "2vw",
    color: "#0E0140",
  },
  cardPrice: {
    lineHeight: "3vw",
    marginTop: "1vw", 
    fontSize: "3.2vw", 
    fontWeight: "bold", 
    fontFamily: "'Acumin Pro', sans-serif",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14.8vw",
      marginTop: "9.4vw",
    }
  },
  cardPricePerMonth: {
    textAlign: "center", 
    fontSize: "1.1vw", 
    color: "#0E0140",
    [theme.breakpoints.down('sm')]: {
      fontSize: "5vw",
      marginTop: "5vw",
    }
  },
  cardPlanFeatures: {
    fontSize: "1.1vw", 
    fontFamily: "'ITC Avant Garde Gothic Std Md', sans-serif", 
    color: "#5f5f5f",
    [theme.breakpoints.down('sm')]: {
      fontSize: "4.5vw",
      marginLeft: "2vw"
    }
  },
  cardButton: {
    width: '13.5rem',
    margin: 'auto',
    padding: '0.5rem 0',
    marginBottom: theme.spacing(4),
    textTransform: "none",
    fontSize: "1.01vw",
    [theme.breakpoints.down('sm')]: {
      fontSize: "4.8vw",
      width: '16.5rem',
      marginTop: "0"
    }
  },
  gradientBg: {
    position: "absolute",
    width: "100%",
    height: "30vw",
    left: "50%",
    top: "0",
    transform: "translateX(-50%)",
    background: "linear-gradient(90deg, #5145FF 2.93%, #973DFE 100%)",
    zIndex: "-1",
    [theme.breakpoints.down('sm')]: {
      height: "501vw",
      top: "-3vw",
    }
  },
  personalPlanCard: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "5vw",
    width: "30vw",
    height: "10vw",
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    [theme.breakpoints.down('sm')]: {
      width: "85vw",
      height: "43vw",
      marginTop: "22vw",
    }
  },
  personalPlanHeading: {
    position: "relative",
    textAlign: "center",
    top: "2vw",
    color: "#5145FF",
    fontFamily: "'ITC Avant Garde Gothic Std Md', 'Montserrat', sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: "5vw",
      width: "80%",
      top: "6vw",
      left: "50%",
      transform: "translateX(-50%)",
    }
  },
  personalPlanButton: {
    position: "relative",
    top: "4vw",
    left: "35%",
    transform: "translateX(-50%)",
    padding: "0.8vw 1.5vw",
    background: "linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)",
    color: "#fff",
    borderRadius: "45px",
    fontSize: "1vw",
    fontWeight: "semibold",
    [theme.breakpoints.down('sm')]: {
    top: "14vw",
    left: "27%",
    fontSize: "4.5vw",
    padding: "2.8vw 4.5vw",
    }
  }
}))

export { useStyles }
