import Footer from '../../../components/Footer'

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./twoFaOptions.style"
import Container from '@material-ui/core/Container';
import { tiers } from './twoFaOptions';
import { Link } from "react-router-dom"
import { TwoFAOptions } from "./twoFaOptions"

export default function TwoFaOtions() {
  const classes = useStyles();
  const { twoFaSms, twoFaEmail, twoFaAuthenticator } = TwoFAOptions();

  console.log(twoFaAuthenticator);
  console.log(twoFaSms);

  return (
    <>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            2-Step Verification
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
          IEMSecure Authentication
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Authenticate quickly with 2FA

        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (

            (tier.twoFA === twoFaSms || tier.twoFA === twoFaAuthenticator || tier.twoFA === twoFaEmail) && (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      {/* Some space here */}
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Link to={tier.route}>
                      <Button className={classes.btn} variant={tier.buttonVariant} color="primary">
                        {tier.buttonText}
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            )
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  )
}
