import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export const subscribeToNewsletter = async (email) => {
    const body = {
        email: email
    }

    const { data } = await axios.post(`${API}/newsletter/subscribe`, body, config)
    console.log(data)
    return data
}
