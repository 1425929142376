import { useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux'
import { reportDetails } from '../../../redux/actions/reportAction'
import { userProfile } from '../../../redux/actions/userProfile'
import { resultUrlAction } from '../../../redux/actions/resultUrlAction';

export const ScanResultsLogic = (resultArray) => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const API = process.env.REACT_APP_API
  const [progress, setProgress] = useState(10);
  const [pending, setPending] = useState(1)

  const scanId = match.params.scanId

  // selector
  const { userInfo } = useSelector((state) => state.userSignin)
  console.log(scanId)

  useEffect(() => {
    dispatch(userProfile(userInfo._id))
    console.log("profile");
  }, [userInfo, dispatch])

  useEffect(() => {
    dispatch(reportDetails(userInfo._id, scanId, API))
  }, [dispatch, pending])


  //For timer in preloader
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 6));
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const { data } = useSelector((state) => state.reportDetails)

  // check if status is running
  if (data && data.status === "running") {
    resultArray.clear()

    data.results &&
      data.results.forEach((d) => {
        if (d.url != resultArray.url) {
          console.log(resultArray.url, "---------url------------");
          resultArray.add(d)
        }
      })
    setTimeout(() => {
      setPending(pending + 1)
      console.log(pending)
    }, 3000)
  }

  // check if status is completed
  if (data && data.status === 'completed') {
    resultArray.clear();
    data.results.forEach((d) => {
      if (d.url !== resultArray.url)
        resultArray.add(d)
    })
  }


  function DaysAgo(data) {
    console.log(data.createdAt, 'Created at')
    return parseInt(
      Math.abs(Date.parse(new Date().toUTCString()) - Date.parse(data.createdAt)) /
      (1000 * 60 * 60 * 24)
    )
  }

  function HoursAgo(data) {
    return parseInt(
      Math.abs(Date.parse(Date()) - Date.parse(data.createdAt)) / (1000 * 60 * 60)
    )
  }

  function MinutesAgo(data) {
    return parseInt(
      Math.abs(Date.parse(new Date().toUTCString()) - Date.parse(data.createdAt)) / (1000 * 60)
    )
  }

  function SecondsAgo(data) {
    return (
      parseInt(Math.abs(Date.parse(new Date().toUTCString()) - Date.parse(data.createdAt)) / 1000)
    )
  }

  console.log('-------Data---------', data)
  const history = useHistory()

  async function redirectCompareText(url) {
    dispatch(resultUrlAction(url));
    history.push(`/compare-text`)
  }



  return { data, userInfo, scanId, HoursAgo, MinutesAgo, SecondsAgo, DaysAgo, progress, redirectCompareText }
}

export function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}