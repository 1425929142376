import { combineReducers } from 'redux'

import { userSignupReducer, userSigninReducer } from './userReducer'
import { userProfileReducer } from './userProfile'
import { reportListReducer, reportDetailsReducer } from './reportReducer'
import { fileUploadReducer, textUploadReducer } from './uploadedDataReducer'
import { resultUrlReducer } from './resultUrlReducer'

const rootReducer = combineReducers({
  userAdd: userSignupReducer,
  userSignin: userSigninReducer,
  reportList: reportListReducer,
  reportDetails: reportDetailsReducer,
  userProfile: userProfileReducer,
  fileUpload: fileUploadReducer,
  textUpload: textUploadReducer,
  resultUrl: resultUrlReducer
})

export default rootReducer
