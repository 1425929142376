import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import PublishIcon from '@material-ui/icons/Publish'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PaymentIcon from '@material-ui/icons/Payment'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import newScan from '../../assets/newScan.svg'
import yourScan from '../../assets/yourScan.svg'
import profile from '../../assets/profile.svg'
import pricing from '../../assets/pricing.svg'
import { Typography } from '@material-ui/core'

export const mainListItems = (
  <div>
    <Link to="/dashboard" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <img src={newScan} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="h6"
                style={{ fontWeight: 500, color: 'black', fontSize: '1.2rem' }}
              >
                New Scan
              </Typography>
            </>
          }
        />
      </ListItem>
    </Link>

    <Link to="/dashboard/scans" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <img src={yourScan} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="h6"
                style={{ fontWeight: 500, color: 'black', fontSize: '1.2rem' }}
              >
                Your Scans
              </Typography>
            </>
          }
        />
      </ListItem>
    </Link>

    <Link to="/dashboard/pricing" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <img src={pricing} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="h6"
                style={{ fontWeight: 500, color: 'black', fontSize: '1.2rem' }}
              >
                Pricing
              </Typography>
            </>
          }
        />
      </ListItem>
    </Link>

    <Link to="/dashboard/profile" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <img src={profile} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="h6"
                style={{ fontWeight: 500, color: 'black', fontSize: '1.2rem' }}
              >
                Profile
              </Typography>
            </>
          }
        />
      </ListItem>
    </Link>
  </div>
)
