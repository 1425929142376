import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_FAILED,
  USER_PROFILE_FETCH,
  USER_PROFILE_DELETE,
  USER_PROFILE_UPDATE,
  USER_PROFILE_DELETE_FAILED,
  USER_PROFILE_UPDATE_FAILED,
} from '../constants/userConstants'

const userProfileReducer = (
  state = {
    error: null,
    data: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
      }
    case USER_PROFILE_FETCH:
      return {
        loading: false,
        data: action.payload,
      }
    case USER_PROFILE_FAILED:
      return {
        error: action.payload,
      }
    case USER_PROFILE_UPDATE:
      return {
        data: action.payload,
      }
    case USER_PROFILE_UPDATE_FAILED:
      return {
        error: action.payload,
      }
    case USER_PROFILE_DELETE:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case USER_PROFILE_DELETE_FAILED:
      return {
        loading: false,
        error: action.payload,
        data: [],
      }
    default:
      return state
  }
}

export { userProfileReducer }
