import { useState } from 'react'
import { useSelector } from 'react-redux'
import { twoFactorAuthenticatorHandler } from './helper'
import swal from "sweetalert"

const TwoFactorAuth = (history) => {
    const { userInfo } = useSelector((state) => state.userSignin)

    const [authToken, setAuthToken] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault();

        const result = await twoFactorAuthenticatorHandler(userInfo.email, authToken);

        if (!result.verified) {
            swal("Oops", "Invalid Token", "error")
        } else {
            swal("Successfully", "Logged In", "success")
            history.push('/dashboard')
        }
    }
    return { submitHandler, authToken, setAuthToken }
}

export default TwoFactorAuth
