import axios from 'axios'

const API = process.env.REACT_APP_API
const FAST_API = process.env.REACT_APP_FAST_API

// const API = "http://localhost:5000"
//const FAST_API = "http://localhost:8000"

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}

// scan text with fast-api
export const uploadText = async (text, userId) => {
  console.log('upload text called')
  const body = {
    userId: userId,
    text: text,
  }

  // const { data } = await axios.post(`${API}/report/submit/text`, body, config)
  const { data } = await axios.post(`${FAST_API}/report/text`, body, config)
  console.log(data)
  return data
}


// credit reduce for particular user 
export const creditReduce = async (wordCount, userId) => {
  console.log('upload text called')
  const body = {
    wordsCount: wordCount,
    userId: userId
  }

  const { data } = await axios.post(`${API}/credit/reduce/${userId}`, body, config)
  console.log(data)
  return data
}

// credit check for text
export const creditCheckForText = async (text, userId) => {
  console.log('credit text called')
  const body = {
    userId: userId,
    text: text,
  }

  const { data } = await axios.post(`${FAST_API}/text_word_count`, body, config)
  // console.log(data)
  return data
}


// spell check and grammer suggestion
export const grammerSuggestion = async (text) => {
  console.log('grammer suggestions called')
  const body = {
    text: text,
  }

  const { data } = await axios.post(`${FAST_API}/grammer_suggestion`, body, config)
  console.log(data)
  return data
}